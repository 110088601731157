import {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import '../Variables.css';
import '../App.css';
import {ToastContainer, toast} from 'react-toastify';
import {nhlFull, nhlAbbrs, nflFull, nflAbbrs, nbaFull, nbaAbbrs, mlbFull, mlbAbbrs, ncaaFull, soccerFull, ncaaAbbrsWConf, soccerAbbrsWConf, f1Full, f1Abbrs, wnbaFull, wnbaAbbrs, aflFull, aflAbbrs} from '../data/teams/teamAbbrs';
import {nflPrefill, nbaPrefill, mlbPrefill, nhlPrefill, ncaaPrefill, soccerPrefill, f1Prefill, wnbaPrefill, aflPrefill} from '../data/prefill/prefillinfo';
import {nhlTeams, nflTeams, nbaTeams, ncaaTeams, mlbTeams, soccerTeams, f1Teams, wnbaTeams, aflTeams} from '../data/teams/variables';
import {baseSettings} from '../data/settings/baseSettings';
import ModalManager from '../components/ModalManager';
import Graphic from '../components/Graphic';
import RichEditor from '../components/RichEditor';
import PaymentPage from '../components/PaymentPage';
import AutoPosts from '../components/AutoPosts';
import SavedPosts from '../components/SavedPosts';
import AboveForm from '../components/AboveForm';
import SavedSettings from '../components/SavedSettings';
import TeamEdit from '../components/TeamEdit';
import moment from 'moment';
import {bracketOptions, chartOptions, comparisonOptions, gridImageOptions, gridOptions, lineupOptions, matchupOptions, newsOptions, postImageOptions, postOptions, scoreOptions, tierOptions} from '../data/styles/settingsOptions';
import FloatingRichEditor from '../components/FloatingRichEditor';
import InlineEditor from '../components/InlineEditor';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import useSettingsStore from '../stores/settings.store';
import useOptionsStore from '../stores/options.store';
import useModalStore from '../stores/modal.store';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';
import useUserStore from '../stores/user.store';
import useGlobalStore from '../stores/global.store';
import usePostStore from '../stores/posts/base-post.store';
import useBracketStore from '../stores/posts/bracket.store';
import useChartStore from '../stores/posts/chart.store';
import useComparisonStore from '../stores/posts/comparison.store';
import useTierStore from '../stores/posts/tier.store';
import useWithImageStore from '../stores/posts/with-image.store';
import { useNavigate } from 'react-router-dom';
import SideBarMenu from '../components/SideBarMenu';
import Header from '../components/Header';
import Formats from '../components/Formats';
import { useUpdateCheck } from 'react-update-notification';
import {Helmet} from "react-helmet";
import Templates from '../components/Templates';
import { getRandomSettings, getSettingsKeys } from '../services/randomSettings';

Modal.setAppElement('#root');
const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
let user;

// eslint-disable-next-line no-extend-native
Array.prototype.sample = function () {
	return this[Math.floor(Math.random() * this.length)];
};

// eslint-disable-next-line no-extend-native
Array.prototype.shuffle = function () {
	for (let i = this.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[this[i], this[j]] = [this[j], this[i]];
	}
	return this;
};

export default function Dashboard () {
	const navigate = useNavigate();
	const [allPosts, setAllPosts] = useState([]);
	const [allCustom, setAllCustom] = useState([]);
	const [allOverrides, setAllOverrides] = useState([]);
	const [allPostsLoading, setAllPostsLoading] = useState(false);
	const clickListenerActive = useRef(false);
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const { settings, updateSetting, updateSettings, replaceSettings } = useSettingsStore();
	const { toggleModal, isModalOpen } = useModalStore(); 
	const { sport, customTeams, teamOverrides, setSport, lockedSettings, currentPost, setLockedSettings, setAllSettings, setSelectedSettings, setSavedPosts, setCustomTeams, setTeamOverrides, setFolders } = useUserStore();
	const { prefilled, teamAbbrs, openEditors, inlineEditor, setTeamAbbrs, setPlayerIds, setSizeMultiplier, setShowImage, setStatFilter, setPrefilled, setWeek: setWeekStore, playerIds, setTeamOptionsStore, setPlayerOptions, isDownloading, isPrefilling, setIsPrefilling, setEditTeam, expanded, setExpanded, draggables: savedDraggables, setDraggables, setHasDraggables, debug, lastSidebar, richEditorSidebar, setRichEditorSidebar } = useGlobalStore();
	const { title, subtitle, description, team, name, stat, points, ranks, image, setTeam, reset: resetPost, setRanks, setName, setStat, setTitle, setSubtitle, setImage, floatingTexts } = usePostStore();
    const { reset: resetBracket } = useBracketStore();
	const { reset: resetChart, xLabel, yLabel, setxLabel, setyLabel } = useChartStore();
	const { reset: resetComparison, leftName, rightName, leftTeam, rightTeam, leftImage, rightImage, leftStats, rightStats, statLabels, setLeftImage, setRightImage, setLeftName, setRightName, setLeftStats, setRightStats, setLeftTeam, setRightTeam, setStatLabels } = useComparisonStore();
	const { reset: resetTier, tiers, temptiers, setTemptiers } = useTierStore();
	const { newstitle, newssubtitle, newsimage, newsteam, newsimagewidth, newsimageheight, reset: resetWithImage, setNewsTitle, setNewsSubtitle, setNewsTeam, setNewsImageWidth, setNewsImageHeight, setNewsImage } = useWithImageStore();
	const { selectedStyle, setSelectedStyle, monoOnly } = useOptionsStore();
	const {
		selectedConference,
		selectedConference2
	} = useOptionsStore();
	const { status, reloadPage } = useUpdateCheck({
		type: 'interval',
		interval: 10000,
	});
	const [localWidth, setLocalWidth] = useState(800);

	// componentDidMount
	useEffect(() => {
		const token = localStorage.getItem('token');
		user = jwtDecode(token).user;
		getUserSettings();
		window.FS?.identify(user._id, {
			displayName: user.name,
			email: user.email,
		});
		resizePost();
		const urlParams = new URLSearchParams(window.location.search);
		const isNew = urlParams.get('welcome');
		if (isNew) {
			toggleModal('quick-settings');
		}
		if (localStorage.getItem('lockedSettings')) {
			setLockedSettings(JSON.parse(localStorage.getItem('lockedSettings')));
		}

		if (user.isActive && document.documentElement.clientWidth > 950) {
			setExpanded('Format');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// componentDidUpdate
	useEffect(() => {
		updateGraphic();
		checkToken();
	});

	const handleSize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEventListener('resize', handleSize);

	useIsomorphicLayoutEffect(() => {
		handleSize();
	}, []);

	useEffect(() => {
		resizePost();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize]);

	useEffect(() => {
		if (!isDownloading) {
			resizePost();
		}
	}, [settings.postWidth, settings.postWidthNews]);

	useEffect(() => {
		if (!isPrefilling) {
			setPrefilled(false);
		}
	}, [title, subtitle, description, team, name, stat, points, ranks, image, xLabel, yLabel, leftName, rightName, leftTeam, rightTeam, leftImage, rightImage, leftStats, rightStats, statLabels, tiers, newstitle, newssubtitle, newsimage, newsteam]);

	useEffect(() => {
		if (expanded === 'Team' && !clickListenerActive.current) {
		  document.addEventListener('click', handleClickOutside, true);
		  clickListenerActive.current = true;
		}

		resizePost();
	
		return () => {
		  document.removeEventListener('click', handleClickOutside, true);
		  clickListenerActive.current = false;
		  resizePost();
		};
	  }, [expanded]);

	  useEffect(() => {
		fixUnavailableSettings(settings);
	  }, [settings]);

	  const handleClickOutside = (event) => {
		if (event.target.closest('.sidebar-content') === null) {
			if (event.target.closest('.off-editing') === null && event.target.closest('.edit-box') === null) {
				event.preventDefault();
				event.stopPropagation();
			}
			setExpanded(lastSidebar);
			setEditTeam(null);
		}
	  };

	const updateUser = (token) => {
		user = jwtDecode(token).user;
	};

	const checkToken = () => {
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/login');
		} else {
			if (!isModalOpen('timeout') && jwtDecode(token).exp < Date.now() / 1000) {
				toggleModal('timeout');
			}
		}
	};

	const getUserSettings = () => {
		try {
			if (user.isActive) {
				axios.get(`/settings/getSettings/${user._id}`).then((response) => {
					if (response.data.length) {
						let settingsToSet = response.data[0].settings;
						replaceSettings(settingsToSet);
						setAllSettings(response.data);
						setSelectedSettings(response.data[0]._id);
					}
				});
				axios.get(`/posts/getPosts/${user._id}`).then((response) => {
					if (response.data) {
						setSavedPosts(response.data);
					}
				});
				axios.get(`/custom/getCustomTeams/${user._id}`).then((response) => {
					if (response.data) {
						response.data.forEach((team) => (team.sport = 'custom'));
						setCustomTeams(response.data);
						if (sport === 'custom') {
							const teamAbbrsToSet = response.data.map((team) => {
								return team['abbreviation'];
							});
							setTeamAbbrs(teamAbbrsToSet);
						}
					}
				});
				axios.get(`/teamoverrides/${user._id}`).then((response) => {
					if (response.data) {
						const savedOverrides = response.data;
						savedOverrides.forEach((override) => {
							let teams = [];
							const overrideSport = override.sport;
							if (overrideSport === 'nhl') teams = nhlTeams;
							else if (overrideSport === 'nba') teams = nbaTeams;
							else if (overrideSport === 'nfl') teams = nflTeams;
							else if (overrideSport === 'ncaa') teams = ncaaTeams;
							else if (overrideSport === 'mlb') teams = mlbTeams;
							else if (overrideSport === 'soccer') teams = soccerTeams;
							else if (overrideSport === 'custom') teams = customTeams;
							else if (overrideSport === 'f1') teams = f1Teams;
							else if (overrideSport === 'wnba') teams = wnbaTeams;
							else if (overrideSport === 'afl') teams = aflTeams;
							const currentTeamObj = teams.find((team) => {
								return team.abbreviation === override.abbreviation;
							});
							Object.assign(override, currentTeamObj);
						});
						setTeamOverrides(savedOverrides);
					}
				});
				axios.get(`/players/playerIds`).then((response) => {
					if (response.data) {
						setPlayerIds(response.data);
					}
				});
				axios.get(`/folders/get/${user._id}`).then((response) => {
					if (response.data) {
						setFolders(response.data);
					}
				});
				if (user.sport !== 'all') {
					sportChange(user.sport);
				} else if (!sport) {
					sportChange('nfl');
				}
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const resizePost = () => {
		let root = document.documentElement;
		let newSettings = {};
		// 72 is width of sidebar plus buffer
		let docWidth = document.documentElement.clientWidth - 110;
		if (document.documentElement.clientWidth <= 950) {
			docWidth = document.documentElement.clientWidth;
			// 50 is height of header plus 72 height of bottom sidebar plus buffer
			docWidth = Math.min(docWidth, (document.documentElement.clientHeight - 130));
		} else {
			// 300 is width of sidepanel content
			if (expanded !== '') docWidth -= 300;
			// 50 is height of header plus buffer
			docWidth = Math.min(docWidth, (document.documentElement.clientHeight - 70));
		}
		const reverseDimensions = settings.postHeight / settings.postWidth;
		newSettings.postWidth = docWidth;
		newSettings.postHeight = docWidth * reverseDimensions;
		const reverseDimensionsNews = settings.postHeightNews / settings.postWidthNews;
		newSettings.postWidthNews = docWidth;
		newSettings.postHeightNews = docWidth * reverseDimensionsNews;
		setSizeMultiplier(docWidth / 800);
		updateSettings(newSettings);
		root.style.setProperty('--sizemultiplier', docWidth / 800);
		moveDraggables();
		setLocalWidth(docWidth);
	};

	const reset = () => {
		resetPost();
		resetBracket();
		resetChart();
		resetComparison();
		resetTier();
		resetWithImage();
		resetDraggables();
	};

	const randomIntFromInterval = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	};

	const randomSettings = () => {
		const selected = selectedStyle.value;
		const newsTeamObj = getTeamObj(newsteam, sport);
		const randomSettings = getRandomSettings(settings, selected, newsTeamObj, monoOnly);
		lockedSettings.forEach((locked) => {
			delete randomSettings[locked];
		});
		const newSettings = Object.assign(settings, randomSettings);
		updateSettings(newSettings);
		if (selected === 'post' && (settings.type.includes('image') || settings.type === 'news')) {
			resetImage(newSettings);
		}
	};

	// just used by quick settings modal
	const setSettings = (settingsToSet) => {
		setSelectedSettings('');
		replaceSettings(settingsToSet);
		resetImage(settingsToSet);
		if (!team.length) {
			prefill();
		}
	};

	const orderList = () => {
		let ranks = [];
		name.map((name, i) => {
			if (stat[i]) {
				// if array is sorted and only contains numbers
				if (stat.every((x, i) => i === 0 || Number(x) <= Number(stat[i - 1])) && !stat.some(isNaN)) {
					const selectedStat = stat[i];
					const index = stat.findIndex((x) => x === selectedStat);
					const count = stat.reduce((n, x) => n + (x === selectedStat), 0);
					if (count > 1) {
						ranks.push(`T-${index + 1}`);
					} else {
						ranks.push(`${i + 1}`);
					}
				} else {
					ranks.push(`${i + 1}`);
				}
			} else {
				ranks.push(`${i + 1}`);
			}
			return name;
		});
		setRanks(ranks);
	};

	const resetSettings = () => {
		const newSettings = Object.assign({}, baseSettings);
		newSettings.type = settings.type;
		if (settings.type === 'matchupimage') {
			newSettings.imageZoomNews = 132;
		}
		lockedSettings.forEach((locked) => {
			if (locked === 'dimensions') {
				newSettings.postWidth = settings.postWidth;
				newSettings.postHeight = settings.postHeight;
				newSettings.postWidthNews = settings.postWidthNews;
				newSettings.postHeightNews = settings.postHeightNews;
			}
			newSettings[locked] = settings[locked];
		});
		setSelectedSettings('');
		updateSettings(newSettings);
		toast.success('Temporarily reset settings (except for locked settings)');
		resetImage(newSettings);
	};

	const resetSomeSettings = () => {
		const selected = selectedStyle.value;
		const keysToReset = getSettingsKeys(settings, selected);
		const originalSettings = {};
		keysToReset.forEach((key) => {
			if (!lockedSettings.includes(key)) {
				originalSettings[key] = baseSettings[key];
			}
		});
		updateSettings(originalSettings);
		resetImage(Object.assign(settings, originalSettings));
		toast.success('Temporarily reset settings (except for locked settings)');
	};

	const resetImage = (settings) => {
		if (settings.type === 'matchupimage') {
			if (settings.orientationMatchup) {
				setShowImage(false);
				if (settings.orientationMatchup.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationMatchup.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationMatchup.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationMatchup.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitmatchupleft', `80`);
				root.style.setProperty('--panesplitmatchupright', `20`);
				setShowImage(true);
			}
		} else if (settings.type === 'news') {
			if (settings.orientationNews) {
				setShowImage(false);
				if (settings.orientationNews.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationNews.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationNews.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationNews.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitnewsleft', `50`);
				root.style.setProperty('--panesplitnewsright', `50`);
				setShowImage(true);
			}
		} else if (settings.type === 'highlightimage') {
			if (settings.orientationHighlight) {
				setShowImage(false);
				if (settings.orientationHighlight.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationHighlight.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationHighlight.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationHighlight.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplithighlightleft', `50`);
				root.style.setProperty('--panesplithighlightright', `50`);
				setShowImage(true);
			}
		} else if (settings.type === 'lineupimage') {
			if (settings.orientationLineup) {
				setShowImage(false);
				if (settings.orientationLineup.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationLineup.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationLineup.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationLineup.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitlineupleft', `50`);
				root.style.setProperty('--panesplitlineupright', `50`);
				setShowImage(true);
			}
		} else if (settings.type.includes('image')) {
			if (settings.orientation) {
				setShowImage(false);
				if (settings.orientation.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientation.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientation.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientation.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitleft', `50`);
				root.style.setProperty('--panesplitright', `50`);
				setShowImage(true);
			}
		}
	};

	const adjustImage = () => {
		const isNewsHighlightOrLineup = (settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage');
		let imageContainerHeight = isNewsHighlightOrLineup ? settings.postHeightNews : settings.postHeight;
		let imageContainerWidth = isNewsHighlightOrLineup ? settings.postWidthNews : settings.postWidth;
		let imageHeight = newsimageheight;
		let imageWidth = newsimagewidth;
		const newsImage = document.getElementById('newsImage');
		let aspectRatio;
		if (newsImage) {
			let parentEl = newsImage.parentElement;
			if (settings.imageStyle?.value === 'style-1' && parentEl) {
				parentEl = parentEl.parentElement;
			}
			if (parentEl) {
				imageContainerHeight = parentEl.offsetHeight;
				imageContainerWidth = parentEl.offsetWidth;
				aspectRatio = newsImage.naturalWidth / newsImage.naturalHeight;
				imageHeight = imageContainerHeight * (settings.imageZoomNews / 100);
				imageWidth = imageHeight * aspectRatio;
				let orientationSetting = settings.orientation;
				if (settings.type === 'news') {
					orientationSetting = settings.orientationNews;
				} else if (settings.type === 'matchupimage') {
					orientationSetting = settings.orientationMatchup;
				} else if (settings.type === 'highlightimage') {
					orientationSetting = settings.orientationHighlight;
				} else if (settings.type === 'lineupimage') {
					orientationSetting = settings.orientationLineup;
				}
				if (orientationSetting.includes('top')) {
					imageWidth = imageContainerWidth * (settings.imageZoomNews / 100)
					imageHeight = imageWidth * aspectRatio;
				}
			}
		}
		if (Math.abs(settings.imageVerticalNews) > Math.abs(imageContainerHeight - imageHeight)) {
			let newHeight = imageContainerHeight - imageHeight;
			updateSetting('imageVerticalNews', newHeight);
		}
		if (imageWidth - imageContainerWidth < Math.abs(settings.imageHorizontalNews)) {
			if (settings.imageHorizontalNews < 0) {
				let newWidth = imageContainerWidth - imageWidth;
				updateSetting('imageHorizontalNews', newWidth);
			} else if (settings.imageHorizontalNews > 0) {
				let newWidth = imageWidth - imageContainerWidth;
				updateSetting('imageHorizontalNews', newWidth);
			}
		}
	};

	const changeType = (type) => {
		if (settings.type === 'tier' && team.includes('#')) {
			let temptiers = [];
			let separator = '#';
			let index = team.indexOf(separator);
			while (index !== -1) {
				temptiers.push(index);
				index = team.indexOf(separator, index + 1);
			}
			setTemptiers(temptiers);
		}
		resetDraggables();
		changeType2(type);
		if (document.documentElement.clientWidth <= 950) {
			setExpanded('');
		}
	};

	const changeType2 = (type) => {
		try {
			if (type === 'news' || settings.type === 'news') {
				reset();
			}
			if (type === 'matchupimage' || settings.type === 'matchupimage') {
				reset();
			}
			let availableOptions = [];
			if (type === 'post') availableOptions = postOptions;
			else if (type === 'grid') availableOptions = gridOptions;
			else if (type === 'postimage') availableOptions = postImageOptions;
			else if (type === 'gridimage') availableOptions = gridImageOptions;
			else if (type === 'tier') availableOptions = tierOptions;
			else if (type === 'bracket') availableOptions = bracketOptions;
			else if (type === 'scores') availableOptions = scoreOptions;
			else if (type === 'matchupimage') availableOptions = matchupOptions;
			else if (type === 'news') availableOptions = newsOptions;
			else if (type === 'highlightimage') availableOptions = newsOptions;
			else if (type === 'lineupimage') availableOptions = lineupOptions;
			else if (type === 'chart') availableOptions = chartOptions;
			else if (type === 'comparison') availableOptions = comparisonOptions;
			if (!availableOptions.some((option) => option.value === selectedStyle.value)) {
				setSelectedStyle(availableOptions[0]);
			} else {
				const foundSetting = availableOptions.find((option) => option.value === selectedStyle.value);
				if (foundSetting.label !== selectedStyle.label) {
					const foundIndex = availableOptions.findIndex((option) => option.value === selectedStyle.value);
					setSelectedStyle(availableOptions[foundIndex]);
				}
			}
			if (type === 'chart') {
				setSelectedStyle(availableOptions.find((option) => option.value === 'chart'));
			}
			updateSetting('type', type);
			if (type.includes('post') || type.includes('grid') || type === 'bracket' || type === 'scores' || type === 'matchupimage' || type === 'highlightimage' || type === 'lineupimage') {
				if (team.includes('#')) {
					const filteredTeam = team.filter((value) => value !== '#');
					setTeam(filteredTeam);
				}
			}
			if (type === 'tier' && !team.includes('#')) {
				let rows = [];
				if (temptiers.length > 1) {
					rows = temptiers;
					rows.map((item, i) => {
						team.splice(item, 0, '#');
						return item;
					});
					setTemptiers([]);
				} else {
					let i = 3;
					while (i < team.length) {
						team.splice(i, 0, '#');
						i = i + 5;
					}
					setTeam(team);
					let rows = [];
					let separator = '#';
					let index = team.indexOf(separator);
					while (index !== -1) {
						rows.push(index);
						index = team.indexOf(separator, index + 1);
					}
					rows.push(200);
				}
			}
			if (prefilled) {
				prefill(type);
			}
			resetImage({...settings, type});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const fixUnavailableSettings = () => {
		if (settings.type === 'highlightimage' || settings.type === 'lineupimage') {
			const availableStyles = [
				'style-1',
				'style-2',
				'style-3',
				'style-4'
			]
			if (!availableStyles.includes(settings.newsStyle?.value)) {
				updateSetting('newsStyle', {value: 'style-1', label: 'Style 1'});
			}
		} else {
			if (settings.imageStyle?.value === 'style-3') {
				if (settings.type === 'news' && settings.newsStyle?.value !== 'style-6') {
					updateSetting('imageStyle', {value: 'style-2', label: 'Style 2'});
				} else if (settings.type.includes('image')) {
					updateSetting('imageStyle', {value: 'style-2', label: 'Style 2'});
				}
			}
		}
	}

	const sportChange = (sport, shouldReset) => {
		if (shouldReset) {
			reset();
		}
		setSport(sport);
		setWeek(sport);
		setTeamOptions(sport);
		if (sport === 'nfl') {
			setStatFilter('fantasyleaders');
		} else {
			setStatFilter('daily');
		}
	};

	const prefill = (newType, chartType) => {
		setIsPrefilling(true);
		let currentSport = sport;
		let type = newType || settings.type;
		let chart = chartType || settings.chartType?.value;
		if (!currentSport) {
			return toast.error('Please select a sport first.');
		}
		if (currentSport === 'custom') return;
		reset();
		const teams = [...teamAbbrs].slice(0, 30);

		let prefillInfo;
		if (currentSport === 'nfl') {
			prefillInfo = JSON.parse(JSON.stringify(nflPrefill));
		} else if (currentSport === 'nba') {
			prefillInfo = JSON.parse(JSON.stringify(nbaPrefill));
		} else if (currentSport === 'mlb') {
			prefillInfo = JSON.parse(JSON.stringify(mlbPrefill));
		} else if (currentSport === 'ncaa') {
			prefillInfo = JSON.parse(JSON.stringify(ncaaPrefill));
		} else if (currentSport === 'nhl') {
			prefillInfo = JSON.parse(JSON.stringify(nhlPrefill));
		} else if (currentSport === 'soccer') {
			prefillInfo = JSON.parse(JSON.stringify(soccerPrefill));
		} else if (currentSport === 'f1') {
			prefillInfo = JSON.parse(JSON.stringify(f1Prefill));
		} else if (currentSport === 'wnba') {
			prefillInfo = JSON.parse(JSON.stringify(wnbaPrefill));
		} else if (currentSport === 'afl') {
			prefillInfo = JSON.parse(JSON.stringify(aflPrefill));
		}
		
		if (type === 'news') {
			setNewsTitle('BREAKING');
			setNewsSubtitle('THIS\nIS\nREALLY\nIMPORTANT\nNEWS');
			setNewsTeam(teams.sample());
		} else if (type === 'highlightimage') {
			let newssubtitle = '50 PTS\n20 AST\n10 REB';
			if (currentSport === 'nfl') newssubtitle = '8 REC\n152 YDS\n1 TD';
			else if (currentSport === 'mlb') newssubtitle = '8 IP\n0 ER\n10 K';
			else if (currentSport === 'nhl') newssubtitle = '3 G\n2 AST\n7 SOG';
			setNewsTitle('');
			setNewsSubtitle(newssubtitle);
			setNewsTeam(teams.sample());
		} else if (type === 'lineupimage') {
			setNewsTitle('Starting Lineup');
			setName([
				'Player 1',
				'Player 2',
				'Player 3',
				'Player 4',
				'Player 5',
				'Player 6',
				'Player 7'
			]);
			setStat([
				'Pos.',
				'Pos.',
				'Pos.',
				'Pos.',
				'Pos.',
				'Pos.',
				'Pos.'
			]);
			setNewsTeam(teams.sample());
		} else if (type === 'comparison') {
			const shuffledArray = prefillInfo.team.map((team, i) => {
				return {
					team,
					name: prefillInfo.name[i],
					image: prefillInfo.image[i],
				};
			});
			shuffledArray.shuffle();
			setTitle('Comparison');
			setSubtitle('Random');
			const teamsToSet = shuffledArray.map((s) => s.team);
			const namesToSet = shuffledArray.map((s) => s.name);
			const imagesToSet = shuffledArray.map((s) => s.image);

			setLeftName(namesToSet[0]);
			setRightName(namesToSet[1]);
			setLeftTeam(teamsToSet[0]);
			setRightTeam(teamsToSet[1]);
			setLeftImage(imagesToSet[0]);
			setRightImage(imagesToSet[1]);
			setLeftStats(Array.from({length: 5}, () => String(randomIntFromInterval(1, 100))));
			setRightStats(Array.from({length: 5}, () => String(randomIntFromInterval(1, 100))));
			setStatLabels(['Label', 'Label', 'Label', 'Label', 'Label']);
			setImageDimensions();
		} else if (type === 'scores') {
			let score = Array.from({length: teams.length}, () => String(randomIntFromInterval(0, 7)));
			if (currentSport === 'nfl' || currentSport === 'ncaa') {
				score = Array.from({length: teams.length}, () => String([0, 3, 7, 10, 14, 17, 20, 24, 27, 28, 35, 42].sample()));
			} else if (currentSport === 'nba' || currentSport === 'wnba' || currentSport === 'afl') {
				score = Array.from({length: teams.length}, () => String(randomIntFromInterval(90, 120)));
			}
			setTitle('Example Scoreboard');
			setSubtitle(moment().format('M/D'));
			setTeam(teams.shuffle());
			setName(score);
		} else if (type === 'matchupimage') {
			const twoTeams = [teams.sample(), teams.sample()];
			let score = Array.from({length: 2}, () => String(randomIntFromInterval(0, 7)));
			if (currentSport === 'nfl' || currentSport === 'ncaa') {
				score = Array.from({length: 2}, () => String([0, 3, 7, 10, 14, 17, 20, 24, 27, 28, 35, 42].sample()));
			} else if (currentSport === 'nba' || currentSport === 'wnba' || currentSport === 'afl') {
				score = Array.from({length: 2}, () => String(randomIntFromInterval(90, 120)));
			}
			setTitle('Example Matchup');
			setSubtitle(moment().format('M/D'));
			setTeam(twoTeams);
			setName(score);
			updateSetting('imageZoomNews', 132);
		} else if (type === 'chart' && chart === 'scatter') {
			setTitle('Example Post');
			setSubtitle('Random');
			setTeam(teams.shuffle());
			setName(Array.from({length: teams.length}, () => randomIntFromInterval(-100, 100)));
			setStat(Array.from({length: teams.length}, () => randomIntFromInterval(-100, 100)));
			setxLabel('x-axis');
			setyLabel('y-axis');
		} else {
			const shuffledArray = prefillInfo.team.map((team, i) => {
				return {
					team,
					name: prefillInfo.name[i],
					image: prefillInfo.image[i],
				};
			});
			shuffledArray.shuffle();
			setTitle('Example Post');
			setSubtitle('Random');
			const teamsToSet = shuffledArray.map((s) => s.team);
			setTeam(teamsToSet);
			const namesToSet = shuffledArray.map((s) => s.name);
			setName(namesToSet);
			setStat(Array.from({length: 10}, () => String(randomIntFromInterval(100, 700))).sort((a, b) => b - a));
			setImage(shuffledArray.map((s) => s.image));
			if (type === 'tier') {
				let i = 3;
				while (i < teamsToSet.length) {
					teamsToSet.splice(i, 0, '#');
					i = i + 5;
				}
				setTeam(teamsToSet);
			} else if (type === 'bracket') {
				let eightteams = teamsToSet.splice(0, 8);
				let eightnames = namesToSet.splice(0, 8);
				setTitle('Bracket of 8');
				setSubtitle('Click a box to advance to next round');
				setTeam(eightteams);
				setName(eightnames);
				setStat([]);
			}
		}
		if (user.is_admin) {
			setNewsImage('https://upload.wikimedia.org/wikipedia/commons/0/03/Joe_Burrow_Bengals.jpg');
		}
		setPrefilled(true);
		setTimeout(() => {
			setIsPrefilling(false);
		});
	};

	const setImageDimensions = () => {
		if (document.getElementById('newsImage')) {
			setNewsImageWidth(document.getElementById('newsImage').offsetWidth);
			setNewsImageHeight(document.getElementById('newsImage').offsetHeight);
		}
	};

	const updateGraphic = () => {
		try {
			let root = document.documentElement;
			
			if (settings.type.includes('post')) {
				// get widest stat element
				let statEls = document.querySelectorAll('.spanstat');
				let widestEl = 0;
				Array.from(statEls).forEach((statEl) => {
					if (statEl.offsetWidth > widestEl) widestEl = statEl.offsetWidth;
				});
				root.style.setProperty('--boxstatminwidth', widestEl + 'px');

				// get player image width
				const playerImage = document.querySelector('.playerimage');
				if (playerImage) {
					root.style.setProperty('--playerimagewidth', playerImage.offsetWidth + 'px');
				} else {
					root.style.setProperty('--playerimagewidth', '0px');
				}

				// get widest image element
				let teamImageEls = document.querySelectorAll('.boximagecontainer');
				let widestTeamImage = 0;
				Array.from(teamImageEls).forEach((imageEl) => {
					if (imageEl.offsetWidth > widestTeamImage) widestTeamImage = imageEl.offsetWidth;
				});
				if (settings.logoOrder === -2 && !settings.overlap) {
					if (settings.playerOrder < -1) {
						root.style.setProperty('--teamimagewidth', widestTeamImage + playerImage?.offsetWidth + 'px');
					} else {
						root.style.setProperty('--teamimagewidth', widestTeamImage + 'px');
					}
				} else {
					if (settings.playerOrder < -1) {
						root.style.setProperty('--teamimagewidth', playerImage?.offsetWidth + 'px');
					} else {
						root.style.setProperty('--teamimagewidth', '0px');
					}
				}
			} else if (settings.type === 'tier') {
				root.style.setProperty('--pyramidboxwidth', '800px');
				// get smallest box width
				const boxes = document.querySelectorAll('.box');
				let smallestEl = settings.postWidth;
				Array.from(boxes).forEach((box) => {
					if (box.offsetWidth < smallestEl) smallestEl = box.offsetWidth;
				});
				root.style.setProperty('--pyramidboxwidth', smallestEl + 'px');
			} else if (settings.type === 'news') {
				const banner = document.querySelector('.newsTitle');
				if (banner) {
					root.style.setProperty('--barheight', banner.offsetHeight + 'px');
				}
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const addToTeamOverrides = (array) => {
		const filteredTeamOverrides = teamOverrides.filter((override) => override.isGlobal);
		array.map((override) => override.isGlobal = false);
		setTeamOverrides(filteredTeamOverrides.concat(array));
	};

	const getImagebyID = (passedName, passedTeam) => {
		try {
			let idsToSet = [];
			let names = passedName ? passedName : name;
			if (!names.length || !names[0].length || !isNaN(names[0])) {
				names = stat;
			}
			if (names.length) {
				setImage([]);
				let teamArray = team;
				if (settings.type === 'tier') {
					teamArray = teamArray.filter((value) => value !== '#');
				}
				names.map((n, i) => {
					if (n.includes(',')) {
						let ids = '';
						const multipleNames = n.split(',');
						if (multipleNames.length <= 3) {
							multipleNames.forEach((nam, idx) => {
								nam = nam
									.toLowerCase()
									.replace('##', ' ')
									.normalize('NFD')
									.replace(' jr', '')
									.replace(' ii', '')
									.replace(/\p{Diacritic}/gu, '')
									.replace(/[^a-zA-Z ]/g, '')
									.trim();
								let filtered = playerIds.filter((obj) => {
									return (
										obj.name
											.toLowerCase()
											.normalize('NFD')
											.replace(' jr', '')
											.replace(' ii', '')
											.replace(/\p{Diacritic}/gu, '')
											.replace(/[^a-zA-Z ]/g, '')
											.indexOf(nam) !== -1 && obj.sport === sport
									);
								});
								if (filtered.length) {
									if (filtered.length > 1) {
										let moreFiltered = filtered.filter((obj) => {
											const selectedTeam = passedTeam ? passedTeam[i] : teamArray[i];
											return isTeam(obj.team, selectedTeam.split('/')[idx]);
										});
										if (moreFiltered.length) {
											ids += moreFiltered[0].id + '/';
										} else {
											ids += filtered[0].id + '/';
											toast.info(`Duplicates found, double check ID for ${nam}`);
										}
									} else {
										ids += filtered[0].id + '/';
									}
								} else {
									ids += 'notfound/';
								}
							});
							ids = ids.slice(0, -1);
							if (ids.includes('notfound')) {
								idsToSet.push('');
							} else {
								idsToSet.push(ids);
							}
						} else {
							idsToSet.push('');
						}
					} else if (n) {
						n = n
							.toLowerCase()
							.replace('##', ' ')
							.normalize('NFD')
							.replace(' jr', '')
							.replace(' ii', '')
							.replace(/\p{Diacritic}/gu, '')
							.replace(/[^a-zA-Z ]/g, '')
							.trim();
						let filtered = playerIds.filter((obj) => {
							return (
								obj.name
									.toLowerCase()
									.normalize('NFD')
									.replace(' jr', '')
									.replace(' ii', '')
									.replace(/\p{Diacritic}/gu, '')
									.replace(/[^a-zA-Z ]/g, '')
									.indexOf(n) !== -1 && obj.sport === sport
							);
						});
						if (filtered.length) {
							let id = filtered[0].id;
							if (filtered.length > 1) {
								let moreFiltered = filtered.filter((obj) => {
									const selectedTeam = passedTeam ? passedTeam[i] : teamArray[i];
									return isTeam(obj.team, selectedTeam)
								});
								if (moreFiltered.length) {
									id = moreFiltered[0].id;
								} else {
									toast.info(`Duplicates found, double check ID for ${n}`);
								}
							}
							idsToSet.push(id);
						} else {
							idsToSet.push('');
						}
					} else {
						idsToSet.push('');
					}
					return name;
				});
				setImage(idsToSet);
			} else {
				toast.error('Please enter player names first');
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
			toast.error('Oops! Something went wrong');
		}
	};

	const getTeam = (t) => {
		t = t.toUpperCase().trim();
		if (t.includes('/')) {
			let team1 = t.split('/')[0];
			let team2 = t.split('/')[1];
			let sport1 = sport;
			let sport2 = sport;
			if (user.plan === 'all' && team1.includes('*')) {
				sport1 = team1.split('*')[0].toLowerCase();
				team1 = team1.split('*')[1];
			}
			if (user.plan === 'all' && team2.includes('*')) {
				sport2 = team2.split('*')[0].toLowerCase();
				team2 = team2.split('*')[1];
			}
			return {sport1, team1, sport2, team2};
		} else if (user.plan === 'all' && t.includes('*')) {
			let s = t.split('*')[0].toLowerCase();
			t = t.split('*')[1];
			return {team: t, sport: s};
		} else {
			return {team: t, sport};
		}
	};

	const setWeek = (sport) => {
		let seasonStart;
		let week;
		const today = moment();
		if (sport === 'nfl') {
			if (today.isBefore('2024-09-04')) {
				week = 1;
			} else {
				seasonStart = moment('2024-09-04');
				week = today.diff(seasonStart, 'week') + 1;
			}
		} else if (sport === 'mlb') {
			if (today.isBefore('2024-03-27')) {
				week = 1;
			} else {
				seasonStart = moment('2024-03-27');
				week = today.diff(seasonStart, 'week');
			}
		} else if (sport === 'nba') {
			if (today.isBefore('2024-10-16')) {
				week = 1;
			} else {
				seasonStart = moment('2024-10-16');
				week = today.diff(seasonStart, 'week') + 1;
			}
		} else if (sport === 'nhl') {
			if (today.isBefore('2024-10-04')) {
				week = 1;
			} else {
				seasonStart = moment('2024-10-04');
				week = today.diff(seasonStart, 'week') + 1;
			}
		} else if (sport === 'ncaa') {
			if (today.isBefore('2024-08-31')) {
				week = 1;
			} else {
				seasonStart = moment('2024-08-31');
				week = today.diff(seasonStart, 'week') + 1;
			}
		}
		setWeekStore(week);
	};

	const isTeam = (playerIdTeam, selectedTeam) => {
		if (!playerIdTeam || !selectedTeam) return false;
		playerIdTeam = playerIdTeam.toUpperCase();
		selectedTeam = selectedTeam.toUpperCase();
		let teams = [];
		if (sport === 'nhl') teams = JSON.parse(JSON.stringify(nhlTeams));
		else if (sport === 'nba') teams = JSON.parse(JSON.stringify(nbaTeams));
		else if (sport === 'nfl') teams = JSON.parse(JSON.stringify(nflTeams));
		else if (sport === 'ncaa') teams = JSON.parse(JSON.stringify(ncaaTeams));
		else if (sport === 'mlb') teams = JSON.parse(JSON.stringify(mlbTeams));
		else if (sport === 'soccer') teams = JSON.parse(JSON.stringify(soccerTeams));
		else if (sport === 'custom') teams = JSON.parse(JSON.stringify(customTeams));
		else if (sport === 'f1') teams = JSON.parse(JSON.stringify(f1Teams));
		else if (sport === 'wnba') teams = JSON.parse(JSON.stringify(wnbaTeams));
		else if (sport === 'afl') teams = JSON.parse(JSON.stringify(aflTeams));
		const foundTeam = teams.find((obj) => {
			return obj.abbreviation === selectedTeam || normalizeTeamName(obj.full) === selectedTeam || obj.abbreviation2 === selectedTeam || normalizeTeamName(obj.full2) === selectedTeam || obj.abbreviation3 === selectedTeam;
		});
		if (!foundTeam) return false;
		return playerIdTeam === foundTeam.abbreviation || playerIdTeam === foundTeam.abbreviation2 || playerIdTeam === foundTeam.abbreviation3 || playerIdTeam === normalizeTeamName(foundTeam.full) || playerIdTeam === normalizeTeamName(foundTeam.full2);
	};

	const normalizeTeamName = (name) => {
		if (name && sport === 'ncaa' && name.includes(' STATE')) {
			name = name.replace(' STATE', ' ST')
		}
		return name?.normalize('NFD').replace(/\p{Diacritic}/gu, '').replace(/[^a-zA-Z0-9&-]/g, '').replace('2','');
	}

	const getTeamObj = (team, sport) => {
		let teams = [];
		if (!team) return {};
		if (sport === 'nhl') teams = JSON.parse(JSON.stringify(nhlTeams));
		else if (sport === 'nba') teams = JSON.parse(JSON.stringify(nbaTeams));
		else if (sport === 'nfl') teams = JSON.parse(JSON.stringify(nflTeams));
		else if (sport === 'ncaa') teams = JSON.parse(JSON.stringify(ncaaTeams));
		else if (sport === 'mlb') teams = JSON.parse(JSON.stringify(mlbTeams));
		else if (sport === 'soccer') teams = JSON.parse(JSON.stringify(soccerTeams));
		else if (sport === 'custom') {
			teams = JSON.parse(JSON.stringify(customTeams));
			if (user.is_admin && allCustom.length && currentPost.user_id !== user.user_id) {
				teams = JSON.parse(JSON.stringify(allCustom.filter((cus) => {
					return cus.user_id === currentPost.user_id;
				})));
			}
		} else if (sport === 'f1') teams = JSON.parse(JSON.stringify(f1Teams));
		else if (sport === 'wnba') teams = JSON.parse(JSON.stringify(wnbaTeams));
		else if (sport === 'afl') teams = JSON.parse(JSON.stringify(aflTeams));
		const teamToFind = normalizeTeamName(team);
		let returnTeam = {};
		const foundTeam = teams.find((obj) => {
			return obj.abbreviation === teamToFind || normalizeTeamName(obj.full) === teamToFind || obj.abbreviation2 === teamToFind || normalizeTeamName(obj.full2) === teamToFind || obj.abbreviation3 === teamToFind;
		});
		if (foundTeam) {
			Object.assign(returnTeam, foundTeam);
			returnTeam.logo = returnTeam.logos[0];
		}
		let overridesToUse = teamOverrides;
		if (user.is_admin && allOverrides.length && currentPost.user_id !== user.user_id) {
			overridesToUse = JSON.parse(JSON.stringify(allOverrides.filter((over) => {
				return over.user_id === currentPost.user_id;
			})));
			overridesToUse = overridesToUse.concat(currentPost.teamoverrides);
		}
		let override = overridesToUse.filter((obj) => {
			return obj && obj.sport === sport && (obj.abbreviation === teamToFind || normalizeTeamName(obj.full) === teamToFind || obj.abbreviation2 === teamToFind || normalizeTeamName(obj.full2) === teamToFind || obj.abbreviation3 === teamToFind);
		});
		if (override && override.length > 1) {
			let localOverride = override.find((obj) => {
				return !obj.isGlobal;
			});
			if (localOverride) {
				if (!localOverride.logo) localOverride.logo = localOverride.logos[0];
				Object.assign(returnTeam, localOverride);
			}
		} else if (override && override.length === 1) {
			if (!override[0].logo) override[0].logo = override[0].logos[0];
			Object.assign(returnTeam, override[0]);
		}
		if (team?.substring(team.length - 1) === '2' && returnTeam) {
			const oldPrimary = returnTeam.primary;
			const oldSecondary = returnTeam.secondary;
			returnTeam = {...returnTeam};
			returnTeam.primary = oldSecondary;
			returnTeam.secondary = oldPrimary;
		}
		if (settings.helmets && foundTeam?.helmet) {
			returnTeam.logo = foundTeam.helmet;
		}
		if (settings.lighterLogos && sport !== 'afl' && returnTeam.logo) {
			returnTeam.logo = returnTeam.logo.replace('/500/', '/500-dark/');
		}
		return returnTeam;
	};

	const resetDraggables = () => {
		setDraggables([]);
		setHasDraggables(false);
		const allDraggables = document.querySelectorAll('.react-draggable');
		const draggables = Array.from(allDraggables).filter(draggable => {
			return !draggable.closest('.floatingText');
		});
		draggables.forEach((draggable, i) => {
			draggable.style.transform = `translate(0px, 0px)`;
			draggable.style.width = `auto`;
			draggable.style.height = `auto`;
			draggable.style.minWidth = null;
			draggable.style.minHeight = null;
		});
		saveDraggables();
	};

	const originalSport = () => {
		sportChange(user.sport, true);
		reset();
	};

	const saveDraggables = () => {
		const returnVal = [];
		const draggables = document.querySelectorAll('.react-draggable');
		draggables.forEach((draggable, i) => {
			let obj = {};
			const transform = draggable.style.transform.split(',');
			let width = draggable.closest('.drag-container') ? draggable.closest('.drag-container').offsetWidth : 0;
			obj.boxWidth = width;
			obj.xvalue = transform[0].match(NUMERIC_REGEXP)[0];
			obj.yvalue = transform[1].match(NUMERIC_REGEXP)[0];
			obj.width = draggable.style.width.replace('px', '');
			obj.height = draggable.style.height.replace('px', '');
			// if image has been moved
			if ((obj.xvalue > 0 || obj.yvalue > 0 || obj.width !== 'auto' || obj.height !== 'auto') && !draggable.closest('.floatingText.drag-container')) {
				setHasDraggables(true);
			}
			returnVal.push(obj);
		});
		setDraggables(returnVal);
	};

	const moveDraggables = () => {
		let draggableInfo = savedDraggables;
		if (draggableInfo) {
			const draggables = document.querySelectorAll('.react-draggable');
			draggables.forEach((draggable, i) => {
			if (draggableInfo[i]) {
				const currentBoxWidth = draggable.closest('.drag-container') ? draggable.closest('.drag-container').offsetWidth : 0;
				const boxWidth = draggableInfo[i].boxWidth;
				const ratio = currentBoxWidth / boxWidth;
				const xvalue = draggableInfo[i].xvalue;
				const yvalue = draggableInfo[i].yvalue;
				const width = draggableInfo[i].width;
				const height = draggableInfo[i].height;
				const newx = xvalue * ratio;
				const newy = yvalue * ratio;
				draggable.style.transform = `translate(${newx}px, ${newy}px)`;
				if (width && !isNaN(width)) {
					const newwidth = width * ratio;
					draggable.style.width = `${newwidth}px`;
				} else {
					draggable.style.width = 'auto';
				}
				if (height && !isNaN(height)) {
					const newheight = height * ratio;
					draggable.style.height = `${newheight}px`;
				} else {
					draggable.style.height = 'auto';
				}
				// if image has been moved
				if ((xvalue > 0 || yvalue > 0 || width !== 'auto' || height !== 'auto') && !draggable.closest('.floatingText.drag-container')) {
					setHasDraggables(true);
				}
			}
			});
		}
	};

	const setTeamOptions = (sport) => {
		let teamOptions = [];
		if (sport === 'nhl') teamOptions = nhlFull;
		else if (sport === 'nba') teamOptions = nbaFull;
		else if (sport === 'nfl') teamOptions = nflFull;
		else if (sport === 'ncaa') teamOptions = ncaaFull;
		else if (sport === 'mlb') teamOptions = mlbFull;
		else if (sport === 'soccer') teamOptions = soccerFull;
		else if (sport === 'f1') teamOptions = f1Full;
		else if (sport === 'wnba') teamOptions = wnbaFull;
		else if (sport === 'afl') teamOptions = aflFull;
		let teamAbbrsArr = [];
		if (sport === 'nhl') teamAbbrsArr = nhlAbbrs;
		else if (sport === 'nba') teamAbbrsArr = nbaAbbrs;
		else if (sport === 'nfl') teamAbbrsArr = nflAbbrs;
		else if (sport === 'wnba') teamAbbrsArr = wnbaAbbrs;
		else if (sport === 'afl') teamAbbrsArr = aflAbbrs;
		else if (sport === 'f1') teamAbbrsArr = f1Abbrs;
		else if (sport === 'mlb') teamAbbrsArr = mlbAbbrs;
		else if (sport === 'ncaa') {
			let teams = ncaaAbbrsWConf.filter((team) => team.conference === selectedConference.value);
			teamAbbrsArr = teams.map((item) => {
				return item['team'];
			});
		} else if (sport === 'soccer') {
			let teams = soccerAbbrsWConf.filter((team) => team.conference === selectedConference2.value);
			teamAbbrsArr = teams.map((item) => {
				return item['team'];
			});
		} else if (sport === 'custom') {
			teamAbbrsArr = customTeams.map((team) => {
				return team['abbreviation'];
			});
		}
		let playerOptions = [];
		if (sport === 'nhl') {
			playerOptions = [
				...new Set(
					playerIds
						.filter((a) => a.sport === 'nhl')
						.map((b) => b.name)
						.sort()
				),
			];
		} else if (sport === 'nba') {
			playerOptions = [
				...new Set(
					playerIds
						.filter((a) => a.sport === 'nba')
						.map((b) => b.name)
						.sort()
				),
			];
		} else if (sport === 'nfl') {
			playerOptions = [
				...new Set(
					playerIds
						.filter((a) => a.sport === 'nfl')
						.map((b) => b.name)
						.sort()
				),
			];
		} else if (sport === 'mlb') {
			playerOptions = [
				...new Set(
					playerIds
						.filter((a) => a.sport === 'mlb')
						.map((b) => b.name)
						.sort()
				),
			];
		}
		setTeamOptionsStore(teamOptions);
		setTeamAbbrs(teamAbbrsArr);
		setPlayerOptions(playerOptions);
	};

	const addTierSeparator = () => {
		setTeam([...team, '#']);
	};

	const getAllPosts = () => {
		setAllPostsLoading(true);
		axios.get(`/admins/allPosts`).then((response) => {
			if (response.data) {
				setAllPosts(response.data);
				setAllPostsLoading(false);
			}
		});
		axios.get(`/admins/customTeams`).then((response) => {
			if (response.data) {
				setAllCustom(response.data);
			}
		});
		axios.get(`/admins/allOverrides`).then((response) => {
			if (response.data) {
				setAllOverrides(response.data);
			}
		});
	}

	let floatingHeight = 0,
		floatingWidth = 0;
	if (openEditors !== null) {
		const floatInput = document.getElementById(`floatinput${openEditors}`);
		if (floatInput) {
			floatingHeight = floatInput.offsetHeight;
			floatingWidth = floatInput.offsetWidth;
		}
	}
	return (
		<div className='Dashboard'>
			<Helmet>
                <title>Stat Lists</title>
            </Helmet>
			<Header
				fromDashboard={true}
				user={user}
				moveDraggables={moveDraggables}
				resizePost={resizePost}
				updateGraphic={updateGraphic}/>
			<ToastContainer />
			{status === 'available' && (
				<div className='update-banner'>
					<span>New update available, please refresh the page</span>
					<button onClick={reloadPage}>Update</button>
				</div>
			)}
			<ModalManager
				getTeamObj={getTeamObj}
				getTeam={getTeam}
				updateUser={updateUser}
				user={user}
				setSettings={setSettings}
				orderList={orderList}
			/>
			{user && (<div className='DashboardContent'>
				<SideBarMenu user={user}/>
				{user.isActive && (<div className={expanded === '' ? 'zero-width sidebar-content' : 'sidebar-content'}>
					<div className={`sidebar-actual-content ${expanded === 'Format' ? 'enter' : 'exit'}`}>
						{expanded === 'Format' && (<Formats changeType={changeType}/>)}
					</div>
					<div className={`sidebar-actual-content ${expanded === 'Posts' ? 'enter' : 'exit'}`}>
						{expanded === 'Posts' && (<SavedPosts
							user={user}
							moveDraggables={moveDraggables}
							changeType2={changeType2}
							resetImage={resetImage}
							setTeamOptions={setTeamOptions}
							resetDraggables={resetDraggables}
							addToTeamOverrides={addToTeamOverrides}
							sportChange={sportChange}
							reset={reset}
						/>)}
					</div>
					<div className={`sidebar-actual-content ${expanded === 'Settings' ? 'enter' : 'exit'}`}>
						{expanded === 'Settings' && (<SavedSettings
							resetSettings={resetSettings}
							resetImage={resetImage}
						/>)}
					</div>
					<div className={`sidebar-actual-content ${expanded === 'Styles' ? 'enter' : 'exit'}`} style={{flexDirection: 'column'}}>
						{expanded === 'Styles' && (<div className="form-check form-switch">
							<input
								className={`form-check-input ${richEditorSidebar ? 'custom-switch-checked' : 'custom-switch-unchecked'}`}
								type="checkbox"
								role="switch"
								id="richEditorSidebarId"
								checked={richEditorSidebar}
								onChange={(e) => {
									setRichEditorSidebar(!richEditorSidebar);
								}}/>
							<label
								className="form-check-label"
								htmlFor="richEditorSidebarId">
									Style editor in sidebar
							</label>
						</div>)}
						{expanded === 'Styles' && richEditorSidebar && (<RichEditor
							updateUser={updateUser}
							getTeamObj={getTeamObj}
							user={user}
							randomSettings={randomSettings}
							resetSomeSettings={resetSomeSettings}
							orderList={orderList}
							setImageDimensions={setImageDimensions}
							adjustImage={adjustImage}
							resetImage={resetImage}
							reset={reset}
							prefill={prefill}
							resetDraggables={resetDraggables}
						/>)}
					</div>
					<div className={`sidebar-actual-content ${expanded === 'Templates' ? 'enter' : 'exit'}`}>
						{expanded === 'Templates' && (<Templates
							user={user}
							moveDraggables={moveDraggables}
							changeType2={changeType2}
							resetImage={resetImage}
							setTeamOptions={setTeamOptions}
							resetDraggables={resetDraggables}
							addToTeamOverrides={addToTeamOverrides}
							sportChange={sportChange}/>)}
					</div>
					<div className={`sidebar-actual-content ${expanded === 'Auto' ? 'enter' : 'exit'}`}>
						{expanded === 'Auto' && (
							<AutoPosts
								reset={reset}
								getTeamObj={getTeamObj}
								getImagebyID={getImagebyID}
								changeType2={changeType2}
							/>
						)}
					</div>
					<div className={`sidebar-actual-content admin-panel ${expanded === 'Admin' ? 'enter' : 'exit'}`}>
					{expanded === 'Admin' && (
						<div>
						<button
							className='newButton allPostsBtn'
							onClick={getAllPosts}>
							Get All Posts
						</button>
						{allPostsLoading && <div className="loading-panel"><i className='fa fa-spinner fa-spin'></i></div>}
						{allPosts.length > 0 && (<SavedPosts
							user={user}
							hideOptions={true}
							moveDraggables={moveDraggables}
							changeType2={changeType2}
							resetImage={resetImage}
							setTeamOptions={setTeamOptions}
							resetDraggables={resetDraggables}
							addToTeamOverrides={addToTeamOverrides}
							allPosts={allPosts}
							sportChange={sportChange}
							reset={reset}
						/>)}
						</div>
					)}
					</div>
					<div className={`sidebar-actual-content ${expanded === 'Team' ? 'enter' : 'exit'}`}>
						{expanded === 'Team' && (
							<TeamEdit
								user={user}
								fromDashboard={true}
								getTeamObj={getTeamObj}
								getTeam={getTeam}
							/>
						)}
					</div>
					{expanded !== '' && (<div className='sidebar-collapse'>
						<button
							onClick={() => setExpanded('')}>
							<i className='fas fa-chevron-left no-click'></i>
						</button>
					</div>)}
				</div>)}
				{!user.isActive && <PaymentPage user={user} />}
				{user.isActive && (
					<div className='Statmaker'>
						<AboveForm
							user={user}
							sportChange={sportChange}
							setImageDimensions={setImageDimensions}
							getImagebyID={getImagebyID}
							orderList={orderList}
							getTeamObj={getTeamObj}
							reset={reset}
							prefill={prefill}
						/>
						<div className='main'>
							<div className={'statmakermiddle'} style={richEditorSidebar ? {marginTop: '25px'} : {}}>
								{!richEditorSidebar && (<RichEditor
									updateUser={updateUser}
									getTeamObj={getTeamObj}
									user={user}
									randomSettings={randomSettings}
									resetSomeSettings={resetSomeSettings}
									orderList={orderList}
									setImageDimensions={setImageDimensions}
									adjustImage={adjustImage}
									resetImage={resetImage}
									reset={reset}
									prefill={prefill}
									resetDraggables={resetDraggables}
								/>)}
								<div
									className='progressdiv hide'
									id='progressbar'
									style={{
										width: localWidth,
										height: localWidth,
									}}>
									<div className='progress-value'></div>
								</div>
								<Graphic
									user={user}
									getTeam={getTeam}
									getTeamObj={getTeamObj}
									originalSport={originalSport}
									adjustImage={adjustImage}
									setImageDimensions={setImageDimensions}
									prefill={prefill}
									setDraggables={saveDraggables}
								/>
								{openEditors !== null && floatingTexts[openEditors] && (
									<div
										className='floatingEditor'
										style={{
											top: floatingTexts[openEditors].yval + 50 + floatingHeight,
											left: floatingWidth < 409 ? floatingTexts[openEditors].xval - (409 - floatingWidth) / 2 : floatingTexts[openEditors].xval + (floatingWidth - 409) / 2,
										}}>
										<FloatingRichEditor/>
									</div>
								)}
								{inlineEditor !== null && (
									<div
										className='floatingEditor'
										style={{
											top: inlineEditor.yval,
											left: inlineEditor.xval,
										}}>
										<InlineEditor />
									</div>
								)}
								{settings.type === 'tier' && (
									<button
										className='newButton center tierBtn'
										onClick={addTierSeparator}>
										Add Tier
									</button>
								)}
								{settings.type === 'bracket' && (
									<button
										className='newButton center tierBtn'
										onClick={resetBracket}>
										Reset Advancements
									</button>
								)}
							</div>
						</div>
					</div>
				)}
			</div>)}
		</div>
	);
}
