import {useEffect, useReducer} from 'react';
import {colord, extend} from 'colord';
import mixPlugin from 'colord/plugins/mix';
import {bracketOptions, chartOptions, comparisonOptions, gridImageOptions, gridOptions, lineupOptions, matchupOptions, newsOptions, postImageOptions, postOptions, scoreOptions, tierOptions} from '../data/styles/settingsOptions';
import ChartFormat from './formats/ChartFormat';
import useSettingsStore from '../stores/settings.store';
import useOptionsStore from '../stores/options.store';
import usePostStore from '../stores/posts/base-post.store';
import useGlobalStore from '../stores/global.store';
import useUserStore from '../stores/user.store';
import PostFormat from './formats/PostFormat';
import ImageFormat from './formats/ImageFormat/ImageFormat';
import Footer from './formats/common/Footer';
import TitleBox from './formats/common/TitleBox';
import GridFormat from './formats/GridFormat';
import TierFormat from './formats/TierFormat';
import BracketFormat from './formats/BracketFormat';
import ScoreFormat from './formats/ScoreFormat';
import ComparisonFormat from './formats/ComparisonFormat';
import FloatingTexts from './formats/common/FloatingTexts';
extend([mixPlugin]);

export default function Graphic(props) {
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const { settings } = useSettingsStore();
	const { setSelectedStyle } = useOptionsStore();
	const { title, team, stat, teamsplits, nameOverrides, statOverrides, nameHighlights, statHighlights, setTeamSplits, combined, thirdTitle } = usePostStore();
	const { individuallyEdit, sizeMultiplier, loading, graphicMap, openEditors, setOpenEditors, setInlineEditor, forceGraphicUpdate, popovers } = useGlobalStore();
	const { sport } = useUserStore();

	useEffect(() => {
		forceUpdate();
	}, [forceGraphicUpdate]);

	const getGraphicBackground = () => {
		let background = settings.backgroundGradientType === 'linear-gradient' ?
			`linear-gradient(${settings.backgroundGradientDirectionDeg}, ` :
			`radial-gradient(circle, `;
		settings.backgroundGradientStops.forEach((stop, i) => {
			background += `${stop.color} ${stop.percentage}%, `
		});
		background = background.slice(0, -2);
		background += ')';
		return background;
	};

	const getBoxBackground = (teamObj) => {
		let boxbackground = '#000000';
		if (!teamObj || (Object.keys(teamObj).length === 0 && teamObj.constructor === Object)) {
			return findColor(settings.teamBackground, teamObj);
		}
		if (settings.teamBackground === 'gradient') {
			const hasSingleTeam = settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage';
			boxbackground = settings[hasSingleTeam ? 'gradientTypeNews' : 'gradientType'] === 'linear-gradient' ?
				`linear-gradient(${hasSingleTeam ? settings.gradientDirectionNews : settings.gradientDirection}, ` :
				`radial-gradient(circle, `;
			settings[hasSingleTeam ? 'gradientStopsNews' : 'gradientStops'].forEach((stop) => {
				let stopColor = findColor(stop.color, teamObj);
				if (stop.colorMix) {
					stopColor = colord(stopColor).mix(stop.color2, stop.colorMix / 100).toHex();
					colord(teamObj.primary).mix('#000000', 0.7).toHex();
				}
				boxbackground += `${stopColor} ${stop.percentage}%, `
			});
			boxbackground = boxbackground.slice(0, -2);
			boxbackground += ')';
		// single color
		} else {
			boxbackground = findColor(settings.teamBackground, teamObj);
		}
		return boxbackground;
	};

	const getTwoTeamBoxBackground = (teamObj1, teamObj2, existingSplit) => {
		let boxbackground = '#000000';

		let firstObj = teamObj1;
		let secondObj = teamObj2;

		const reversedSplits = [
			'cliptopright/clipbottomleft',
			'clipright/clipleft',
			'clipbottomright/cliptopleft',
			'halfleft/halfright'
		];
		if (reversedSplits.includes(existingSplit?.splitDirection)) {
			firstObj = teamObj2;
			secondObj = teamObj1;
		}
		if (Number(settings.gradientDirection.replace('deg', '')) > 180) {
			firstObj = teamObj2;
			secondObj = teamObj1;
			if (!reversedSplits.includes(existingSplit?.splitDirection)) {
				firstObj = teamObj1;
				secondObj = teamObj2;
			}
		}

		if (settings.teamBackground === 'gradient') {
			boxbackground = settings.gradientType === 'linear-gradient' ?
				`linear-gradient(${settings.gradientDirection}, ` :
				`radial-gradient(circle, `;
			settings.gradientStops.forEach((stop, i) => {
				const half = Math.floor(settings.gradientStops.length / 2);
				const objToUse = (i <= half) ? firstObj : secondObj;
				const otherObj = (i <= half) ? secondObj : firstObj;
				let stopColor = findTwoTeamColor(stop.color, objToUse, otherObj);
				if (stop.colorMix) {
					stopColor = colord(stopColor).mix(stop.color2, stop.colorMix / 100).toHex();
					colord(objToUse.primary).mix('#000000', 0.7).toHex();
				}
				boxbackground += `${stopColor} ${stop.percentage}%, `
			});
			boxbackground = boxbackground.slice(0, -2);
			boxbackground += ')';
			// single color
		} else {
			boxbackground = findColor(settings.teamBackground, firstObj);
		}
		return boxbackground;
	};

	const getNameStyle = (i, fontSize, text) => {
		let returnVal = {};
		const nameOverride = nameOverrides.find((n) => n.id === i);
		if (nameOverride) {
			returnVal.fontFamily = nameOverride.font.value.includes('bold') ? nameOverride.font.value.replaceAll(' ', '') : nameOverride.font.value;
			returnVal.fontSize = fontSize ? `calc(${fontSize} * ${nameOverride.fontSize})` : `calc(${settings.nameSize}px * ${nameOverride.fontSize} * ${sizeMultiplier})`;
			returnVal.color = nameOverride.fontColor;
			returnVal.letterSpacing = nameOverride.letterSpacing;
		} else if (fontSize) {
			returnVal.fontSize = fontSize;
		}
		const highlight = nameHighlights.find((highlight) => highlight.text.toLowerCase() === text.toLowerCase());
		if (highlight) {
			returnVal.color = highlight.color;
		}
		if (text && String(text).includes('##')) {
			returnVal.whiteSpace = 'pre';
		}
		return returnVal;
	};

	const getStatStyle = (i, text, fontSize, fiveless, height) => {
		let returnVal = {};
		const statOverride = statOverrides.find((s) => s.id === i);
		if (fiveless) {
			if (statOverride) {
				returnVal.fontFamily = statOverride.font.value.includes('bold') ? statOverride.font.value.replaceAll(' ', '') : statOverride.font.value;
				returnVal.color = statOverride.fontColor;
				returnVal.letterSpacing = statOverride.letterSpacing;
				returnVal.fontSize = (settings.statSize - 24) * sizeMultiplier + (height / 3.5) * statOverride.fontSize + 'px';
				returnVal.bottom = settings.statMargin * sizeMultiplier - height * 0.09 + 'px';
			} else {
				returnVal.fontSize = (settings.statSize - 24) * sizeMultiplier + height / 3.5 + 'px';
				returnVal.bottom = settings.statMargin * sizeMultiplier - height * 0.09 + 'px';
			}
		} else {
			if (statOverride) {
				returnVal.fontFamily = statOverride.font.value.includes('bold') ? statOverride.font.value.replaceAll(' ', '') : statOverride.font.value;
				returnVal.fontSize = fontSize ? `calc(${fontSize} * ${statOverride.fontSize})` : `calc(${settings.statSize}px * ${statOverride.fontSize} * ${sizeMultiplier})`;
				returnVal.color = statOverride.fontColor;
				returnVal.letterSpacing = statOverride.letterSpacing;
			} else if (fontSize) {
				returnVal.fontSize = fontSize;
			}
		}
		const highlight = statHighlights.find((highlight) => highlight.text === text);
		if (highlight) {
			returnVal.color = highlight.color;
		}
		if (settings.statHighlight) {
			if (Number(text) === findLargestValue(stat)) {
				returnVal.color = settings.topHighlight;
			} else if (Number(text) === findSmallestValue(stat)) {
				returnVal.color = settings.bottomHighlight;
			}
		}
		if (text && String(text).includes('##')) {
			returnVal.whiteSpace = 'pre';
		}
		return returnVal;
	};

	const findLargestValue = (arr) => {
		if (!Array.isArray(arr) || arr.length === 0) {
			return undefined; // Return undefined for invalid input
		}

		let largest = Number(arr[0]); // Initialize the largest variable with the first element

		for (let i = 1; i < arr.length; i++) {
			const currentNum = Number(arr[i]); // Convert the current string to a number

			if (!isNaN(currentNum) && currentNum > largest) {
				largest = currentNum; // Update the largest value if the current number is greater
			}
		}

		return largest;
	};

	const findSmallestValue = (arr) => {
		if (!Array.isArray(arr) || arr.length === 0) {
			return undefined; // Return undefined for invalid input
		}

		let smallest = Number(arr[0]); // Initialize the smallest variable with the first element

		for (let i = 1; i < arr.length; i++) {
			const currentNum = Number(arr[i]); // Convert the current string to a number

			if (!isNaN(currentNum) && currentNum < smallest) {
				smallest = currentNum; // Update the smallest value if the current number is smaller
			}
		}

		return smallest;
	};

	const selectSettings = async (val) => {
		if (popovers.length) return;
		let type = settings.type;
		let availableOptions = [];
		if (type === 'post') availableOptions = postOptions;
		else if (type === 'grid') availableOptions = gridOptions;
		else if (type === 'postimage') availableOptions = postImageOptions;
		else if (type === 'gridimage') availableOptions = gridImageOptions;
		else if (type === 'tier') availableOptions = tierOptions;
		else if (type === 'bracket') availableOptions = bracketOptions;
		else if (type === 'scores') availableOptions = scoreOptions;
		else if (type === 'matchupimage') availableOptions = matchupOptions;
		else if (type === 'highlightimage') availableOptions = newsOptions;
		else if (type === 'lineupimage') availableOptions = lineupOptions;
		else if (type === 'news') availableOptions = newsOptions;
		else if (type === 'chart') availableOptions = chartOptions;
		else if (type === 'comparison') availableOptions = comparisonOptions;
		const value = availableOptions.find((opt) => opt.value === val);
		setSelectedStyle(value);
	};

	const flipClip = (e) => {
		let teamSplitsCopy = [...teamsplits];
		let datateam = e.target.getAttribute('data-team').toUpperCase();
		let existingSplit = teamSplitsCopy.find((split) => split.team === datateam);
		let splitDirection = existingSplit ? existingSplit.splitDirection : 'cliptopleft/clipbottomright';
		switch (splitDirection) {
			case 'cliptopleft/clipbottomright':
				splitDirection = 'cliptop/clipbottom';
				break;
			case 'cliptop/clipbottom':
				splitDirection = 'cliptopright/clipbottomleft';
				break;
			case 'cliptopright/clipbottomleft':
				splitDirection = 'clipright/clipleft';
				break;
			case 'clipright/clipleft':
				splitDirection = 'clipbottomright/cliptopleft';
				break;
			case 'clipbottomright/cliptopleft':
				splitDirection = 'clipbottom/cliptop';
				break;
			case 'clipbottom/cliptop':
				splitDirection = 'clipbottomleft/cliptopright';
				break;
			case 'clipbottomleft/cliptopright':
				splitDirection = 'clipleft/clipright';
				break;
			case 'clipleft/clipright':
				splitDirection = 'halfleft/halfright';
				break;
			case 'halfleft/halfright':
				splitDirection = 'halfright/halfleft';
				break;
			case 'halfright/halfleft':
				splitDirection = 'cliptopleft/clipbottomright';
				break;
			default:
			// do nothing
		}
		if (existingSplit) {
			existingSplit.splitDirection = splitDirection;
			setTeamSplits(teamSplitsCopy);
		} else {
			let newSplit = {};
			newSplit.team = datateam;
			newSplit.splitDirection = splitDirection;
			teamSplitsCopy.push(newSplit);
			setTeamSplits(teamSplitsCopy);
		}
	};

	const getImg = (i) => {
		let img = String(i);
		if (!img.includes('http')) {
			let returnVal = [];
			let playerIDs = img.split('/');
			playerIDs.forEach((playerID) => {
				let imgsport = sport;
				let imgsrc = playerID;
				if (imgsport === 'ncaa') {
					imgsport = 'college-football';
				}
				if (playerID.includes('*')) {
					imgsport = playerID.split('*')[0].toLowerCase();
					imgsrc = playerID.split('*')[1];
					imgsport = imgsport === 'ncaa' ? 'college-football' : imgsport;
					imgsport = imgsport === 'ncaam' ? 'mens-college-basketball' : imgsport;
				}
				returnVal.push(`https://a.espncdn.com/i/headshots/${imgsport}/players/full/${imgsrc}.png`);
			});
			return returnVal;
		} else {
			return [i];
		}
	};

	const findColor = (color, teamObj) => {
		if (!teamObj || (Object.keys(teamObj).length === 0 && teamObj.constructor === Object)) {
			if (color === 'none') return 'none';
			return colord(color).isValid() ? color : '#000000';
		}
		if (color === 'primary' || color === 'secondary') {
			return teamObj[color];
		} else if (color === 'primaryDarker') {
			return colord(teamObj.primary).darken(0.1).toHex()
		} else if (color === 'secondaryDarker') {
			return colord(teamObj.secondary).darken(0.1).toHex()
		} else if (color === 'brighter') {
			return colord(teamObj.primary).brightness() > colord(teamObj.secondary).brightness() ? teamObj.primary : teamObj.secondary;
		} else if (color === 'darker') {
			return colord(teamObj.secondary).brightness() > colord(teamObj.primary).brightness() ? teamObj.primary : teamObj.secondary;
		} else if (color === 'mid') {
			return colord(teamObj.primary).mix(teamObj.secondary).toHex();
		}
		return color;
	}

	const findTwoTeamColor = (color, teamObj, teamObj2) => {
		if (!teamObj || (Object.keys(teamObj).length === 0 && teamObj.constructor === Object)) {
			if (color === 'none') return 'none';
			return colord(color).isValid() ? color : '#000000';
		}
		if (color === 'primary' || color === 'secondary') {
			return teamObj.primary;
		} else if (color === 'primaryDarker') {
			return colord(teamObj.primary).darken(0.1).toHex()
		} else if (color === 'secondaryDarker') {
			return colord(teamObj.primary).darken(0.1).toHex()
		} else if (color === 'brighter') {
			return colord(teamObj.primary).brightness() > colord(teamObj.secondary).brightness() ? teamObj.primary : teamObj.secondary;
		} else if (color === 'darker') {
			return colord(teamObj.secondary).brightness() > colord(teamObj.primary).brightness() ? teamObj.primary : teamObj.secondary;
		} else if (color === 'mid') {
			return colord(teamObj.primary).mix(teamObj2?.primary).toHex();
		}
		return color;
	}

	const formatMap = () => {
		if (settings.type === 'post') {
			return <PostFormat getTeamObj={props.getTeamObj} getTeam={props.getTeam} user={props.user} getBoxBackground={getBoxBackground} getTwoTeamBoxBackground={getTwoTeamBoxBackground} getNameStyle={getNameStyle} getStatStyle={getStatStyle} getImg={getImg} selectSettings={selectSettings} openInlineEditor={openInlineEditor} flipClip={flipClip} homePage={props.homePage} findColor={findColor}></PostFormat>
		} else if (settings.type === 'grid') {
			return <GridFormat getTeamObj={props.getTeamObj} getTeam={props.getTeam} getBoxBackground={getBoxBackground} getTwoTeamBoxBackground={getTwoTeamBoxBackground} getImg={getImg} selectSettings={selectSettings} openInlineEditor={openInlineEditor} flipClip={flipClip} homePage={props.homePage} setDraggables={props.setDraggables} findColor={findColor}></GridFormat>
		} else if (settings.type === 'tier') {
			return <TierFormat getTeamObj={props.getTeamObj} getTeam={props.getTeam} getBoxBackground={getBoxBackground} getTwoTeamBoxBackground={getTwoTeamBoxBackground} getNameStyle={getNameStyle} getStatStyle={getStatStyle} getImg={getImg} selectSettings={selectSettings} openInlineEditor={openInlineEditor} flipClip={flipClip} homePage={props.homePage} findColor={findColor}></TierFormat>
		} else if (settings.type === 'bracket') {
			return <BracketFormat getTeamObj={props.getTeamObj} getTeam={props.getTeam} getBoxBackground={getBoxBackground} getTwoTeamBoxBackground={getTwoTeamBoxBackground} getImg={getImg} selectSettings={selectSettings} homePage={props.homePage} setDraggables={props.setDraggables} findColor={findColor}></BracketFormat>
		} else if (settings.type === 'scores') {
			return <ScoreFormat getTeamObj={props.getTeamObj} getTeam={props.getTeam} user={props.user} getBoxBackground={getBoxBackground} selectSettings={selectSettings} homePage={props.homePage} findColor={findColor}></ScoreFormat>
		} else if (settings.type === 'comparison') {
			return <ComparisonFormat getTeamObj={props.getTeamObj} getBoxBackground={getBoxBackground} getImg={getImg} selectSettings={selectSettings} setDraggables={props.setDraggables} findColor={findColor}></ComparisonFormat>
		} else if (settings.type === 'chart') {
			let titleHeight = thirdTitle ? (settings.titleHeight + 4) : settings.titleHeight;
			let chartHeight = settings.postHeight * ((100 - titleHeight) / 100);
			if (settings.hasFooter) {
				chartHeight -= settings.footerHeight * sizeMultiplier;
			}
			return (
				<div style={{width: settings.postWidth, height: chartHeight}}>
					<ChartFormat
						width={settings.postWidth}
						height={chartHeight}
						getTeamObj={props.getTeamObj}
						homePage={props.homePage}
						findColor={findColor}
					/>
				</div>
			);
		}
	};

	const toggleFloatEditor = (i) => {
		if (!individuallyEdit) {
			return;
		}
		if (openEditors !== i) {
			setOpenEditors(i);
			document.addEventListener('click', handleClickOutside, true);
		}
	};

	const handleClickOutside = (event) => {
		if (event.target.closest('.floatingText') === null && event.target.closest('.floatingEditor') === null && event.target.closest('.react-tiny-popover-container') === null) {
			setOpenEditors(null);
			document.removeEventListener('click', handleClickOutside, true);
		}
	};

	const openInlineEditor = (element, type, id, extraY = 0) => {
		if (!individuallyEdit) {
			return;
		}
		const parentPos = document.querySelector('.statmakermiddle').getBoundingClientRect();
		const childPos = element.getBoundingClientRect();
		setInlineEditor({
			element,
			type,
			id,
			xval: childPos.left - parentPos.left,
			yval: childPos.bottom - parentPos.top,
		});
		document.addEventListener('click', handleClickOutsideTwo, true);
	};

	const handleClickOutsideTwo = (event) => {
		if (event.target.closest('.floatingText') === null && event.target.closest('.floatingEditor') === null && event.target.closest('.react-tiny-popover-container') === null) {
			setInlineEditor(null);
			document.removeEventListener('click', handleClickOutsideTwo, true);
		}
	};

	let postStyle = {};
	let classes = settings.type;
	if (settings.type.includes('image') || settings.type === 'news') {
		classes = '';
	}
	if (settings.type === 'matchupimage') {
		classes = 'matchup';
	}
	if (settings.type === 'bracket') {
		if (team.length <= 4) {
			classes += ' bracket4';
		} else if (team.length > 4 && team.length <= 8) {
			classes += ' bracket8';
		} else if (team.length === 12) {
			classes += ' bracket12';
		} else if (team.length > 8 && team.length <= 16) {
			classes += ' bracket16';
		} else if (team.length > 16 && team.length <= 32) {
			classes += ' bracket32';
		} else if (team.length > 32) {
			classes += ' bracket64';
		}
		if (settings.bracketOneSided) {
			classes += ' one-sided';
		}
	}
	if (settings.type === 'tier') {
		let rows = [];
		let teams = team;
		let separator = '#';
		let index = teams.indexOf(separator);
		while (index !== -1) {
			rows.push(index);
			index = teams.indexOf(separator, index + 1);
		}
		rows.push(200);
		if (settings.unevenTiers) {
			let templateRows = thirdTitle ? `${settings.titleHeight + 4}%` : `${settings.titleHeight}%`;
			rows.forEach((_row, i) => {
				let newRow = ' 1fr';
				if (teams.length) {
					const rowCount = teams
						.join(',')
						.split('#')
						[i].split(',')
						.filter((n) => n).length;
					if (rowCount > settings.tierSplit) {
						newRow = ' 2fr';
					}
				}
				templateRows += newRow;
			});
			postStyle.gridTemplateRows = templateRows;
		} else {
			let templateRows = thirdTitle ? `${settings.titleHeight + 4}%` : `${settings.titleHeight}%`;
			rows.forEach((_row, i) => {
				templateRows += ' 1fr';
			});
			postStyle.gridTemplateRows = templateRows;
		}
	}
	if (settings.type.includes('grid')) {
		if (settings.type.includes('image')) {
			classes = 'grid';
		}
		if (team.length === 1) {
			classes += ' onegrid';
		} else if (team.length > 1 && team.length <= 2) {
			classes += ' twogrid';
		} else if (team.length > 2 && team.length <= 4) {
			classes += ' fourgrid';
		} else if (team.length > 4 && team.length <= 6) {
			classes += ' sixgrid';
		} else if (team.length > 6 && team.length <= 9) {
			classes += ' ninegrid';
		} else if (team.length > 9 && team.length <= 12) {
			classes += ' twelvegrid';
		} else if (team.length > 12 && team.length <= 16) {
			classes += ' sixteengrid';
		} else if (team.length > 16 && team.length <= 20) {
			classes += ' twentygrid';
		} else if (team.length > 20 && team.length <= 25) {
			classes += ' twentyfivegrid';
		} else if (team.length > 25 && team.length <= 30) {
			classes += ' thirtygrid';
		} else if (team.length > 30 && team.length <= 36) {
			classes += ' thirtysixgrid';
		} else if (team.length > 36 && team.length <= 42) {
			classes += ' fortytwogrid';
		} else if (team.length > 42 && team.length <= 49) {
			classes += ' fortyninegrid';
		} else if (team.length > 49 && team.length <= 56) {
			classes += ' fiftysixgrid';
		} else if (team.length > 56 && team.length <= 64) {
			classes += ' sixtyfourgrid';
		} else if (team.length > 64 && team.length <= 72) {
			classes += ' seventytwogrid';
		} else if (team.length > 72 && team.length <= 81) {
			classes += ' eightyonegrid';
		} else if (team.length > 81 && team.length <= 90) {
			classes += ' ninetygrid';
		} else if (team.length > 90) {
			classes += ' hundredgrid';
		}
		let numTeams = Math.min(team.length, 100);
		const nearest_sq = (n) => Math.pow(Math.round(Math.sqrt(n)), 2);
		let nearest = nearest_sq(numTeams);
		let sqrt = Math.sqrt(nearest);
		if (settings.gridColumns) {
			sqrt = settings.gridColumns;
		}
		postStyle.gridTemplateColumns = `repeat(${sqrt}, 1fr)`;
		let titleHeight = thirdTitle ? `${settings.titleHeight + 4}%` : `${settings.titleHeight}%`;
		postStyle.gridTemplateRows = `${titleHeight} repeat(${Math.ceil(numTeams / sqrt)}, 1fr)`;
	}
	if (settings.postBackground) {
		postStyle.backgroundImage = `var(--postbackground)`;
	} else if (settings.backgroundColor === 'gradient') {
		postStyle.background = getGraphicBackground();
	} else {
		postStyle.backgroundColor = settings.headerColor;
	}
	if (settings.type.includes('post')) {
		let maplength = team.length;
		if (maplength < 5) {
			maplength = 5;
		} else if (maplength >= 100) {
			maplength = 100;
		}
		if (settings.type.includes('image')) {
			classes = 'post';
		}
		let titleHeight = thirdTitle ? `${settings.titleHeight + 4}%` : `${settings.titleHeight}%`;
		if ((maplength > 75 && !settings.postColumns) || settings.postColumns === 4) {
			classes += ' fourcolumns';
			postStyle.gridTemplateRows = `${titleHeight} repeat(${Math.round(maplength / 4)}, 1fr)`;
		} else if ((maplength > 50 && !settings.postColumns) || settings.postColumns === 3) {
			classes += ' threecolumns';
			postStyle.gridTemplateRows = `${titleHeight} repeat(${Math.ceil(maplength / 3)}, 1fr)`;
		} else if ((maplength > 20 && !settings.postColumns) || settings.postColumns === 2) {
			classes += ' twocolumns';
			postStyle.gridTemplateRows = `${titleHeight} repeat(${Math.round(maplength / 2)}, 1fr)`;
		} else {
			postStyle.gridTemplateRows = `${titleHeight} repeat(${maplength}, 1fr)`;
		}
		if (settings.hasFooter) {
			postStyle.paddingBottom = `calc(var(--footerheight) * var(--sizemultiplier))`;
		}
	}
	if (settings.type === 'comparison') {
		let titleHeight = thirdTitle ? `${settings.titleHeight + 4}%` : `${settings.titleHeight}%`;
		postStyle.gridTemplateRows = `${titleHeight} 1fr`;
	}
	if (props.user.plan !== 'all' && props.user.sport !== sport) {
		return (
			<div
				className='postcontainer'
				id='postcontainer'>
				<div
					className={settings.type}
					id='post'
					style={{backgroundColor: 'black'}}>
					<div className='addmore'>
						Selected sport doesn't match your plan. Please upgrade your plan to access more than one sport.
						<br />
						<button
							className='newButton newLine'
							onClick={props.originalSport}>
							Reset
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		if (settings.type.includes('image') || settings.type === 'news') {
			if (graphicMap) {
				return <ImageFormat postStyle={postStyle} classes={classes} getTeamObj={props.getTeamObj} getTeam={props.getTeam} user={props.user} getGraphicBackground={getGraphicBackground} getBoxBackground={getBoxBackground} getTwoTeamBoxBackground={getTwoTeamBoxBackground} getNameStyle={getNameStyle} getStatStyle={getStatStyle} getImg={getImg} selectSettings={selectSettings} openInlineEditor={openInlineEditor} flipClip={flipClip} toggleFloatEditor={toggleFloatEditor} setImageDimensions={props.setImageDimensions} adjustImage={props.adjustImage} homePage={props.homePage} setDraggables={props.setDraggables} prefill={props.prefill} findColor={findColor}></ImageFormat>;
			} else {
				return (
					<div
						className='postcontainer'
						id='postcontainer'>
						<div
							className={settings.type}
							id='post'
							style={{backgroundColor: 'black'}}></div>
					</div>
				);
			}
		} else {
			return (
				<div
					className='postcontainer'
					id='postcontainer'>
					<div
						className={classes}
						id='post'
						style={postStyle}>
						{graphicMap && <TitleBox user={props.user} selectSettings={selectSettings} homePage={props.homePage}></TitleBox>}
						{loading && (
							<img
								id='loadingIcon'
								src='loading.gif'
								alt='loading'
							/>
						)}
						{!team.length && sport && settings.type !== 'comparison' && !props.homePage && (
							<div className='addmore'>
								Begin by entering one or more team names
								<br />
								{sport !== 'custom' && <button
									className='newButton newLine showExample'
									onClick={() => props.prefill()}>
									Show Example
								</button>}
							</div>
						)}
						{props.user.is_admin && combined && (
							<div className='subheaderbar'>
								<span>Team</span>
								<span>Player</span>
								<span>NFL.com</span>
								<span>ESPN</span>
								<span>Fantasy Pros</span>
								<span>Opp.</span>
								<span>Opp. Rank</span>
							</div>
						)}
						{graphicMap && formatMap()}
						{props.user.is_admin && title === 'STANDINGS' && sport === 'nba' && <div className='standingsborder'></div>}
						<FloatingTexts setDraggables={props.setDraggables} toggleFloatEditor={toggleFloatEditor}></FloatingTexts>
						{settings.hasFooter && settings.type !== 'bracket' && <Footer selectSettings={selectSettings}></Footer>}
					</div>
				</div>
			);
		}
	}
}
