import { colord, random } from 'colord';
import { fontOptions } from '../data/styles/fontOptions';
import { alignmentOptions } from '../data/styles/alignmentOptions';
import { backgroundColorOptions, colorOptions, limitedColorOptions } from '../data/styles/colorOptions';
import { textureOptions } from '../data/styles/textureOptions';
import { imageStyleOptions, imageStyleOptionsNews, styleOptions, styleOptionsNews } from '../data/styles/styleOptions';

// eslint-disable-next-line no-extend-native
Array.prototype.sample = function () {
	return this[Math.floor(Math.random() * this.length)];
};

const randomIntFromInterval = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};

const randomFloatFromInterval = (min, max) => {
	const randomFloat = Math.random() * (max - min) + min;
	return Math.round(randomFloat * 10) / 10;
};

const randomColorMostlyOne = (color, includeGradient) => {
	if (includeGradient) {
		return Array(3).fill(color).concat([random().toHex()]).concat(['gradient', 'gradient']).sample();
	}
	return Array(5).fill(color).concat([random().toHex()]).sample();
};

export const getRandomSettings = (settings, selected, newsTeamObj, monoOnly) => {
	const midGradient = randomIntFromInterval(0, 100);
	const lowGradient = randomIntFromInterval(0, midGradient * 0.75);
	const highGradient = randomIntFromInterval(Math.min(midGradient * 1.25, 100), 100);
	let newsColorOptions = [random().toHex(), '#ffffff', '#000000', ...(newsTeamObj ? [newsTeamObj.primary, newsTeamObj.secondary] : [])];
	const headerColorNews = newsColorOptions.sample();
	const headerFontColorNews = newsColorOptions
		.filter((color) => {
			if (color === headerColorNews) return false;
			return colord(headerColorNews).isDark() ? !colord(color).isDark() : colord(color).isDark();
		})
		.sample();
	const nameColorNews = newsColorOptions
		.filter((color) => {
			return !colord(color).isDark();
		})
		.sample();
	const duplicateLogoSize = randomIntFromInterval(200, 700);
	const boxMargin = randomIntFromInterval(0, 15);
	const availableFonts = monoOnly ? fontOptions.filter((option) => option.mono) : fontOptions;
	const allRandom = {
		headerSize: randomIntFromInterval(16, 24),
		nameSize: randomIntFromInterval(16, 24),
		statSize: randomIntFromInterval(16, 24),
		logoOrder: settings.type.includes('post') ? randomIntFromInterval(-2, 4) : randomIntFromInterval(0, 1),
		logoOpacity: randomIntFromInterval(66, 100),
		logoSize: randomIntFromInterval(30, 90),
		headerSpacing: randomIntFromInterval(-3, 3),
		nameMargin: randomIntFromInterval(-2, 2),
		headerFont: availableFonts.map((a) => a.value).sample(),
		nameFont: availableFonts.map((a) => a.value).sample(),
		statFont: availableFonts.map((a) => a.value).sample(),
		headerColor: randomColorMostlyOne('#000000'),
		headerFontColor: randomColorMostlyOne('#ffffff', true),
		watermarkFont: availableFonts.map((a) => a.value).sample(),
		watermarkSize: randomIntFromInterval(25, 35),
		watermarkWidth: randomIntFromInterval(65, 85),
		statMargin: randomIntFromInterval(-2, 2),
		watermarkColor: randomColorMostlyOne('#ffffff'),
		nameShadow: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		borderColor: randomColorMostlyOne('#ffffff'),
		nameSpacing: randomIntFromInterval(-2, 2),
		statSpacing: randomIntFromInterval(-2, 2),
		statShadow: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		logoPosition: randomIntFromInterval(0, 200),
		statMargin2: randomIntFromInterval(-2, 2),
		lineHeight: randomFloatFromInterval(0.8, 1.2),
		nameBackground: ['#000000', '#000000', '#000000', '#000000', 'secondary', 'primary'].sample(),
		statBackground: ['#000000', '#000000', '#000000', '#000000', 'secondary', 'primary'].sample(),
		nameColor: randomColorMostlyOne('#ffffff'),
		statColor: randomColorMostlyOne('#ffffff'),
		headerSizeNews: randomIntFromInterval(16, 24),
		headerFontNews: availableFonts.map((a) => a.value).sample(),
		headerMarginNews: randomIntFromInterval(0, 8),
		headerSpacingNews: randomIntFromInterval(-3, 3),
		headerColorNews,
		headerFontColorNews,
		headerTextGradientType: ['linear-gradient', 'radial-gradient'].sample(),
		headerTextGradientStops: [
			{ color: randomColorMostlyOne('#ffffff'), percentage: lowGradient },
			{ color: randomColorMostlyOne('#5d5d5d'), percentage: midGradient },
		],
		headerTextGradientDirection: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
		logoOpacityNews: randomIntFromInterval(70, 100),
		nameColorNews,
		logoSizeNews: randomIntFromInterval(300, 600),
		orientation: ['leftright', 'topbottom', 'rightleft', 'bottomtop'].sample(),
		orientationNews: ['leftright', 'topbottom', 'rightleft', 'bottomtop'].sample(),
		orientationMatchup: ['leftright', 'topbottom', 'rightleft', 'bottomtop'].sample(),
		orientationHighlight: ['leftright', 'topbottom', 'rightleft', 'bottomtop'].sample(),
		orientationLineup: ['leftright', 'topbottom', 'rightleft', 'bottomtop'].sample(),
		borderRadius: randomIntFromInterval(0, 15),
		scoreMargin: randomIntFromInterval(5, 15),
		duplicate: [false, true].sample(),
		logoShadow: randomIntFromInterval(0, 5),
		nameMargin2: randomIntFromInterval(5, 15),
		tierFont: availableFonts.map((a) => a.value).sample(),
		tierColor: randomColorMostlyOne('#ffffff'),
		tierSize: randomIntFromInterval(60, 80),
		duplicateLogoSize,
		duplicateLogoPosition: randomIntFromInterval(0, Math.max(settings.postWidth - duplicateLogoSize, 0)),
		duplicateLogoOpacity: randomIntFromInterval(5, 15),
		gridMargin: randomIntFromInterval(1, 7),
		logoFlip: [true, false].sample(),
		scoreMinHeight: randomIntFromInterval(100, 200),
		headerOutlineColor: randomColorMostlyOne('#ffffff'),
		nameOutlineColor: randomColorMostlyOne('#ffffff'),
		statOutlineColor: randomColorMostlyOne('#ffffff'),
		headerOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		nameOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		statOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		headerTransform: ['none', 'uppercase'].sample(),
		nameTransform: ['none', 'uppercase'].sample(),
		statTransform: ['none', 'uppercase'].sample(),
		nameShadowColor: randomColorMostlyOne('#000000'),
		statShadowColor: randomColorMostlyOne('#000000'),
		tierTitle: ['left', 'top', 'bottom'].sample(),
		pyramid: [true, false].sample(),
		newsStyle: settings.type === 'news' ? styleOptionsNews.sample() : styleOptions.sample(),
		postBackground: Array(30)
			.fill(null)
			.concat([
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background1.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background2.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background3.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background4.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background5.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background6.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background7.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background8.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background9.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background10.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background11.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background12.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background13.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background14.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background15.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background16.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background17.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background18.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background19.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background20.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background21.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background22.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background23.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background24.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background25.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background26.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
				'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background27.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
			])
			.sample(),
		subtitleFontSize: randomIntFromInterval(8, 12),
		subtitleSpacing: randomIntFromInterval(-2, 2),
		subtitleFont: availableFonts.map((a) => a.value).sample(),
		subtitleFontColor: randomColorMostlyOne('#ffffff', true),
		subtitleOutlineColor: randomColorMostlyOne('#ffffff'),
		subtitleOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		subtitleTransform: ['none', 'uppercase'].sample(),
		subtitleTextGradientType: ['linear-gradient', 'radial-gradient'].sample(),
		subtitleTextGradientStops: [
			{ color: randomColorMostlyOne('#ffffff'), percentage: lowGradient },
			{ color: randomColorMostlyOne('#5d5d5d'), percentage: midGradient },
		],
		subtitleTextGradientDirection: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
		backgroundColor: ['single', 'gradient'].sample(),
		rankSize: randomIntFromInterval(20, 26),
		rankFont: availableFonts.map((a) => a.value).sample(),
		rankFontColor: randomColorMostlyOne('#ffffff'),
		rankMargin: randomIntFromInterval(-2, 2),
		rankSpacing: randomIntFromInterval(-2, 2),
		rankOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		rankOutlineColor: randomColorMostlyOne('#000000'),
		logoSizeGrid: randomIntFromInterval(50, 150),
		logoSizeTier: randomIntFromInterval(50, 150),
		logoSizeBracket: randomIntFromInterval(50, 150),
		bracketMinimized: [true, false].sample(),
		bracketOneSided: [true, false].sample(),
		pointsSize: randomIntFromInterval(10, 25),
		pointsFont: availableFonts.map((a) => a.value).sample(),
		pointsFontColor: randomColorMostlyOne('#ffffff'),
		pointsMargin: randomIntFromInterval(-20, 20),
		pointsMargin2: randomIntFromInterval(-20, 20),
		pointsSpacing: randomFloatFromInterval(0.8, 1.2),
		pointsOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		pointsOutlineColor: randomColorMostlyOne('#000000'),
		matchupPointsBackground: [random().toHex(), 'secondary', 'primary', '#00000000'].sample(),
		pointsBackground: randomColorMostlyOne('#000000'),
		playerHeight: randomFloatFromInterval(0.8, 1.2),
		statLineHeight: randomFloatFromInterval(0.8, 1.2),
		indexAxis: ['x', 'y'].sample(),
		labelSize: randomIntFromInterval(10, 18),
		labelColor: randomColorMostlyOne('#ffffff'),
		labelFont: availableFonts.map((a) => a.value).sample(),
		chartBorderColor: randomColorMostlyOne('#ffffff'),
		chartBorderWidth: randomIntFromInterval(1, 3),
		chartLogoSize: randomIntFromInterval(50, 100),
		playerMargin: randomIntFromInterval(0, 8),
		texture: Array(10)
			.fill({ value: null })
			.concat(textureOptions)
			.map((a) => a.value)
			.sample(),
		textureSize: randomIntFromInterval(1, 7),
		textureOpacity: randomIntFromInterval(5, 25),
		matchupRankBackground: [random().toHex(), 'secondary', 'primary', '#00000000'].sample(),
		rankBackground: [randomColorMostlyOne('#000000'), 'secondary', 'primary'].sample(),
		boxMarginTop: boxMargin,
		boxMarginRight: boxMargin,
		boxMarginBottom: boxMargin,
		boxMarginLeft: boxMargin,
		teamBackground: ['gradient', 'primary'].sample(),
		rankWidth: randomIntFromInterval(35, 50),
		rankMargin2: randomIntFromInterval(-2, 2),
		boxRankOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		borderSize: randomIntFromInterval(1, 3),
		headerBorderSize: randomIntFromInterval(0, 4),
		headerBorderColor: randomColorMostlyOne('#ffffff'),
		boxImageBackground: 'none',
		logoDropShadow: randomIntFromInterval(0, 5),
		footerHeight: randomIntFromInterval(25, 35),
		footerSpacing: randomIntFromInterval(-2, 2),
		footerMargin: randomIntFromInterval(-2, 2),
		footerFont: availableFonts.map((a) => a.value).sample(),
		footerSize: randomIntFromInterval(16, 24),
		footerFontColor: randomColorMostlyOne('#ffffff'),
		footerBackground: randomColorMostlyOne('#000000'),
		footerOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		footerOutlineColor: randomColorMostlyOne('#000000'),
		footerAlignment: alignmentOptions.sample().value,
		tierBackground: '#00000000',
		subtitleSizeNews: randomIntFromInterval(15, 25),
		subtitleMarginNews: randomIntFromInterval(2, 10),
		subtitleSpacingNews: randomIntFromInterval(-3, 3),
		subtitleFontNews: availableFonts.map((a) => a.value).sample(),
		matchupStyle: styleOptions.sample(),
		imageShadowColor: randomColorMostlyOne('#000000'),
		imageShadowSize: randomIntFromInterval(20, 200),
		descriptionSize: randomIntFromInterval(20, 30),
		descriptionMargin: randomIntFromInterval(-2, 2),
		descriptionSpacing: randomIntFromInterval(-2, 2),
		descriptionFont: availableFonts.map((a) => a.value).sample(),
		descriptionFontColor: randomColorMostlyOne('#ffffff'),
		descriptionOutlineColor: randomColorMostlyOne('#000000'),
		descriptionOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		descriptionTransform: ['none', 'uppercase'].sample(),
		comparisonRadius: randomIntFromInterval(5, 15),
		comparisonMargin: randomIntFromInterval(15, 25),
		comparisonBorderSize: randomIntFromInterval(0, 3),
		comparisonBorderColor: randomColorMostlyOne('#ffffff'),
		labelsSize: randomIntFromInterval(15, 25),
		labelsMargin: randomIntFromInterval(3, 9),
		labelsSpacing: randomIntFromInterval(0, 2),
		labelsFont: availableFonts.map((a) => a.value).sample(),
		labelsFontColor: randomColorMostlyOne('#ffffff'),
		labelsBackground: randomColorMostlyOne('#000000'),
		labelsTransform: ['none', 'uppercase'].sample(),
		boxGlowWidth: randomIntFromInterval(0, 20),
		boxGlowColor: [random().toHex(), 'secondary', 'primary'].sample(),
		imageLinesColor: limitedColorOptions.sample().value,
		badgeLineHeightNews: 0,
		titleBackground: limitedColorOptions.sample().value,
		textBackground: limitedColorOptions.sample().value,
		teamContainerBackground: limitedColorOptions.sample().value,
		teamBackgroundLeft: [...backgroundColorOptions, { value: 'gradient' }].sample().value,
		imageStyle: settings.type === 'highlightimage' || settings.type === 'lineupimage' ? imageStyleOptions.sample() : imageStyleOptionsNews.sample(),
		logoVerticalMargin: 0,
		logoHorizontalMargin: 0,
		logoVerticalMarginNews: 0,
		logoHorizontalMarginNews: 0,
		nameAlignment: 'fill',
		statAlignment: 'fill',
		unevenTiers: [true, false].sample(),
		footerPadding: randomIntFromInterval(0, 8),
		playerOrder: randomIntFromInterval(-3, 4),
		rankInside: [true, false].sample(),
		rankMarginReal: randomIntFromInterval(7, 13),
		rankOutsideMargin: randomIntFromInterval(-20, 0),
		rankGlowWidth: randomIntFromInterval(0, 20),
		rankGlowColor: [random().toHex(), 'secondary', 'primary'].sample(),
		rankBorderRadius: randomIntFromInterval(0, 10),
		postStatBackground: Array(5).fill({ value: 'none' }).concat(colorOptions).sample().value,
		postStatPadding: randomIntFromInterval(0, 20),
		postStatHeight: randomIntFromInterval(50, 100),
		postStatBorderRadius: randomIntFromInterval(0, 10),
		gradientType: ['linear-gradient', 'radial-gradient'].sample(),
		gradientStops: [
			{ color: backgroundColorOptions.sample().value, percentage: lowGradient },
			{ color: backgroundColorOptions.sample().value, percentage: midGradient },
			{ color: backgroundColorOptions.sample().value, percentage: highGradient },
		],
		gradientDirection: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
		gradientDirectionNews: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
		gradientTypeNews: ['linear-gradient', 'radial-gradient'].sample(),
		gradientStopsNews: [
			{ color: backgroundColorOptions.sample().value, percentage: lowGradient },
			{ color: backgroundColorOptions.sample().value, percentage: midGradient },
			{ color: backgroundColorOptions.sample().value, percentage: highGradient },
		],
		backgroundGradientDirectionDeg: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
		backgroundGradientType: ['linear-gradient', 'radial-gradient'].sample(),
		backgroundGradientStops: [
			{ color: random().toHex(), percentage: 0 },
			{ color: random().toHex(), percentage: 100 },
		],
		leftBackgroundGradientType: ['linear-gradient', 'radial-gradient'].sample(),
		leftBackgroundGradientStops: [
			{ color: backgroundColorOptions.sample().value, percentage: lowGradient },
			{ color: backgroundColorOptions.sample().value, percentage: midGradient },
			{ color: backgroundColorOptions.sample().value, percentage: highGradient },
		],
		leftBackgroundGradientDirection: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
		nonHeadshot: [true, false].sample(),
		playerAspectRatio: randomFloatFromInterval(0.5, 1.5),
		thirdTitleFontSize: randomIntFromInterval(8, 12),
		thirdTitleSpacing: randomIntFromInterval(-2, 2),
		thirdTitleFont: fontOptions.map((a) => a.value).sample(),
		thirdTitleFontColor: randomColorMostlyOne('#ffffff', true),
		thirdTitleOutlineColor: randomColorMostlyOne('#ffffff'),
		thirdTitleOutlineSize: [0, 0, 0, 0, 0, 0, 1, 2].sample(),
		thirdTitleTransform: ['none', 'uppercase'].sample(),
		thirdTitleTextGradientType: ['linear-gradient', 'radial-gradient'].sample(),
		thirdTitleTextGradientStops: [
			{ color: randomColorMostlyOne('#ffffff'), percentage: lowGradient },
			{ color: randomColorMostlyOne('#5d5d5d'), percentage: midGradient },
		],
		thirdTitleTextGradientDirection: ['0deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg'].sample(),
	};
	let keysToRandomize = getSettingsKeys(settings, selected);
	let selectedRandomSettings = {};
	keysToRandomize.forEach((key) => {
		if (allRandom[key]) {
			selectedRandomSettings[key] = allRandom[key];
		}
	});
	return selectedRandomSettings;
};

export const getSettingsKeys = (settings, selected) => {
	const isImage = settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage';
	let keysToRandomize;
	switch (selected) {
		case 'layout':
			keysToRandomize = layoutSettingsKeys;
			break;
		case 'actualheader':
			keysToRandomize = actualHeaderSettingsKeys;
			break;
		case 'header':
			keysToRandomize = isImage ? headerSettingsImageKeys : headerSettingsKeys;
			break;
		case 'subtitle':
			keysToRandomize = isImage ? subtitleSettingsImageKeys : subtitleSettingsKeys;
			break;
		case 'thirdTitle':
			keysToRandomize = thirdTitleSettingsKeys;
			break;
		case 'team':
			keysToRandomize = isImage ? teamSettingsImageKeys : teamSettingsKeys;
			break;
		case 'name':
			keysToRandomize = isImage ? nameSettingsImageKeys : nameSettingsKeys;
			break;
		case 'stat':
			keysToRandomize = isImage ? statSettingsImageKeys : statSettingsKeys;
			break;
		case 'post':
			keysToRandomize = isImage ? postSettingsImageKeys : postSettingsKeys;
			break;
		case 'tier':
			keysToRandomize = tierSettingsKeys;
			break;
		case 'footer':
			keysToRandomize = footerSettingsKeys;
			break;
		case 'image':
			keysToRandomize = imageSettingsKeys;
			break;
		case 'rank':
			keysToRandomize = rankSettingsKeys;
			break;
		case 'description':
			keysToRandomize = descriptionSettingsKeys;
			break;
		case 'points':
			keysToRandomize = pointsSettingsKeys;
			break;
		case 'player':
			keysToRandomize = playerSettingsKeys;
			break;
		case 'chart':
			keysToRandomize = chartSettingsKeys;
			break;
		case 'labels':
			keysToRandomize = labelsSettingsKeys;
			break;
		default:
		//nothing
	}
	return keysToRandomize;
};

// non-randomized and non-reset settings
// type
// watermark
// videoDirection
// videoReverse
// hasFooter
// footerText
// chartType

// non-randomized settings
// "overlap",
// "imageHorizontalNews",
// "imageVerticalNews",
// "imageZoomNews",
// "paneSplit",
// "paneSplitNews",
// "paneSplitMatchup",
// "paneSplitHighlight",
// "paneSplitLineup",
// "postWidth",
// "postHeight",
// "postWidthNews",
// "postHeightNews",
// "titleHeight",
// "postColumns",
// "gridColumns",
// "tierSplit",
// "fivelessBreak",
// "helmets",
// "lighterLogos",
// "actualHeaderColor",
// "statHighlight",
// "topHighlight",
// "bottomHighlight",
// "lineHeightNews",
// "lineHeightTextNews",
// "imageTitleColor",
// "backgroundText",
// "line1size",
// "line2size",
// "line3size",
// "line1sizestat",
// "line2sizestat",
// "line3sizestat",
// "subtitleBackground",
// "subtitleMarginTop",
// "subtitleMarginRight",
// "subtitleMarginBottom",
// "subtitleMarginLeft",
// "subtitlePaddingTop",
// "subtitlePaddingRight",
// "subtitlePaddingBottom",
// "subtitlePaddingLeft",
// "subtitleAlignment",
// "subtitleAlignmentNews",
// "titleBackgroundColor",
// "titleMarginTop",
// "titleMarginRight",
// "titleMarginBottom",
// "titleMarginLeft",
// "titlePaddingTop",
// "titlePaddingRight",
// "titlePaddingBottom",
// "titlePaddingLeft",
// "headerAlignment",
// "headerAlignmentNews",
// "thirdTitleBackground",
// "thirdTitleMarginTop",
// "thirdTitleMarginRight",
// "thirdTitleMarginBottom",
// "thirdTitleMarginLeft",
// "thirdTitlePaddingTop",
// "thirdTitlePaddingRight",
// "thirdTitlePaddingBottom",
// "thirdTitlePaddingLeft",
// "thirdTitleAlignment"

// if included in these keys but not in allRandom above,
// then it will only be reset and not randomized
export const layoutSettingsKeys = [
	'boxMarginTop',
	'boxMarginRight',
	'boxMarginBottom',
	'boxMarginLeft',
	'borderRadius',
	'gridMargin',
	'pyramid',
	'unevenTiers',
	'tierTitle',
	'bracketMinimized',
	'bracketOneSided',
];
export const actualHeaderSettingsKeys = [
	'watermarkFont',
	'watermarkSize',
	'watermarkWidth',
	'watermarkColor',
	'headerBorderSize',
	'headerBorderColor',
	'titleHeight',
	'actualHeaderColor'
];
export const headerSettingsKeys = [
	'headerFont',
	'headerSize',
	'headerFontColor',
	'headerSpacing',
	'headerTransform',
	'headerOutlineColor',
	'headerOutlineSize',
	'headerTextGradientDirection',
	'headerTextGradientType',
	'headerTextGradientStops',
	'titleBackgroundColor',
	'headerAlignment',
	'titleMarginTop',
	'titleMarginRight',
	'titleMarginBottom',
	'titleMarginLeft',
	'titlePaddingTop',
	'titlePaddingRight',
	'titlePaddingBottom',
	'titlePaddingLeft',
];
export const headerSettingsImageKeys = [
	'headerColorNews',
	'headerFontColorNews',
	'headerSizeNews',
	'headerFontNews',
	'headerMarginNews',
	'headerSpacingNews',
	'titleBackground',
	'lineHeightNews',
	'imageTitleColor',
	'headerAlignmentNews'
];
export const subtitleSettingsKeys = [
	'subtitleFontSize',
	'subtitleSpacing',
	'subtitleFont',
	'subtitleFontColor',
	'subtitleOutlineColor',
	'subtitleOutlineSize',
	'subtitleTransform',
	'subtitleTextGradientDirection',
	'subtitleTextGradientType',
	'subtitleTextGradientStops',
	'subtitleBackground',
	'subtitleMarginTop',
	'subtitleMarginRight',
	'subtitleMarginBottom',
	'subtitleMarginLeft',
	'subtitlePaddingTop',
	'subtitlePaddingRight',
	'subtitlePaddingBottom',
	'subtitlePaddingLeft',
	'subtitleAlignment',
];
export const subtitleSettingsImageKeys = [
	'nameColorNews',
	'subtitleSizeNews',
	'subtitleMarginNews',
	'subtitleSpacingNews',
	'subtitleFontNews',
	'textBackground',
	'lineHeightTextNews',
	'badgeLineHeightNews',
	'subtitleAlignmentNews',
];
export const thirdTitleSettingsKeys = [
	'thirdTitleFontSize',
	'thirdTitleSpacing',
	'thirdTitleFont',
	'thirdTitleFontColor',
	'thirdTitleOutlineColor',
	'thirdTitleOutlineSize',
	'thirdTitleTransform',
	'thirdTitleTextGradientType',
	'thirdTitleTextGradientStops',
	'thirdTitleTextGradientDirection',
	'thirdTitleBackground',
	'thirdTitleMarginTop',
	'thirdTitleMarginRight',
	'thirdTitleMarginBottom',
	'thirdTitleMarginLeft',
	'thirdTitlePaddingTop',
	'thirdTitlePaddingRight',
	'thirdTitlePaddingBottom',
	'thirdTitlePaddingLeft',
	'thirdTitleAlignment',
];
export const teamSettingsKeys = [
	'logoSize',
	'logoOrder',
	'logoShadow',
	'logoDropShadow',
	'logoOpacity',
	'logoPosition',
	'logoVerticalMargin',
	'logoHorizontalMargin',
	'boxImageBackground',
	'duplicate',
	'duplicateLogoSize',
	'duplicateLogoPosition',
	'duplicateLogoOpacity',
	'texture',
	'textureSize',
	'textureOpacity',
	'gradientDirection',
	'gradientStops',
	'gradientType',
	'teamBackground',
	'borderSize',
	'borderColor',
	'boxGlowWidth',
	'boxGlowColor',
	'logoSizeGrid',
	'logoSizeTier',
	'logoFlip',
	'scoreMargin',
	'scoreMinHeight',
	'logoSizeBracket',
	'overlap',
	'helmets',
	'lighterLogos',
];
export const teamSettingsImageKeys = [
	'logoOpacityNews',
	'logoSizeNews',
	'texture',
	'textureSize',
	'textureOpacity',
	'teamBackground',
	'gradientDirectionNews',
	'gradientStopsNews',
	'gradientTypeNews',
	'teamContainerBackground',
	'logoVerticalMarginNews',
	'logoHorizontalMarginNews',
];
export const nameSettingsKeys = [
	'nameFont',
	'nameSize',
	'nameColor',
	'nameMargin',
	'nameMargin2',
	'nameSpacing',
	'lineHeight',
	'nameTransform',
	'nameOutlineColor',
	'nameOutlineSize',
	'nameShadow',
	'nameShadowColor',
	'nameBackground',
	'line1size',
	'line2size',
	'line3size',
	'nameAlignment',
];
export const nameSettingsImageKeys = ['nameFont', 'nameSize', 'nameShadow', 'nameTransform'];
export const statSettingsKeys = [
	'statFont',
	'statSize',
	'statMargin',
	'statSpacing',
	'statShadow',
	'statMargin2',
	'statColor',
	'statOutlineColor',
	'statOutlineSize',
	'statTransform',
	'statShadowColor',
	'statLineHeight',
	'statBackground',
	'postStatBackground',
	'postStatPadding',
	'postStatHeight',
	'postStatBorderRadius',
	'line1sizestat',
	'line2sizestat',
	'line3sizestat',
	'statHighlight',
	'topHighlight',
	'bottomHighlight',
	'statAlignment',
];
export const statSettingsImageKeys = ['statFont', 'statSize'];
export const postSettingsKeys = [
	'postBackground',
	'backgroundColor',
	'headerColor',
	'orientation',
	'orientationMatchup',
	'matchupStyle',
	'backgroundGradientDirectionDeg',
	'backgroundGradientType',
	'backgroundGradientStops',
	'comparisonRadius',
	'comparisonMargin',
	'comparisonBorderSize',
	'comparisonBorderColor',
	'paneSplit',
	'postColumns',
	'gridColumns',
	'tierSplit',
	'fivelessBreak',
	'postWidth',
	'postHeight',
];
export const postSettingsImageKeys = [
	'newsStyle',
	'orientationNews',
	'orientationHighlight',
	'orientationLineup',
	'imageLinesColor',
	'paneSplitNews',
	'paneSplitHighlight',
	'paneSplitLineup',
	'paneSplitMatchup',
	'postWidthNews',
	'postHeightNews',
];
export const tierSettingsKeys = ['tierFont', 'tierColor', 'tierSize', 'tierBackground'];
export const footerSettingsKeys = [
	'footerSize',
	'footerMargin',
	'footerSpacing',
	'footerFont',
	'footerFontColor',
	'footerAlignment',
	'footerHeight',
	'footerBackground',
	'footerOutlineSize',
	'footerOutlineColor',
	'footerPadding',
];
export const imageSettingsKeys = [
	'imageStyle',
	'imageShadowColor',
	'imageShadowSize',
	'teamBackgroundLeft',
	'leftBackgroundGradientDirection',
	'leftBackgroundGradientType',
	'leftBackgroundGradientStops',
	'imageHorizontalNews',
	'imageVerticalNews',
	'imageZoomNews',
	'backgroundText',
];
export const rankSettingsKeys = [
	'rankSize',
	'rankFont',
	'rankBackground',
	'rankWidth',
	'rankFontColor',
	'rankMargin',
	'rankMargin2',
	'rankMarginReal',
	'rankOutsideMargin',
	'rankSpacing',
	'rankOutlineSize',
	'rankOutlineColor',
	'rankInside',
	'rankGlowWidth',
	'rankGlowColor',
	'rankBorderRadius',
	'matchupRankBackground',
	'boxRankOutlineSize',
];
export const descriptionSettingsKeys = [
	'descriptionSize',
	'descriptionFont',
	'descriptionOutlineSize',
	'descriptionTransform',
	'descriptionMargin',
	'descriptionSpacing',
	'descriptionFontColor',
	'descriptionOutlineColor',
];
export const pointsSettingsKeys = [
	'pointsSize',
	'pointsFont',
	'pointsFontColor',
	'pointsMargin',
	'pointsMargin2',
	'pointsSpacing',
	'pointsOutlineSize',
	'pointsOutlineColor',
	'pointsBackground',
	'matchupPointsBackground',
];
export const playerSettingsKeys = ['playerHeight', 'nonHeadshot', 'playerAspectRatio', 'playerMargin', 'playerOrder'];
export const chartSettingsKeys = ['indexAxis', 'labelSize', 'labelColor', 'labelFont', 'chartBorderColor', 'chartBorderWidth', 'chartLogoSize'];
export const labelsSettingsKeys = ['labelsSize', 'labelsMargin', 'labelsSpacing', 'labelsFont', 'labelsFontColor', 'labelsBackground', 'labelsTransform'];
