import {useState} from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {settingsStyles} from '../data/styles/settingsStyles';
import moment from 'moment';
import {toast} from 'react-toastify';
import {tenSettings} from '../data/adminSettings/tenSettings';
import {twentyFiveSettings} from '../data/adminSettings/twentyFiveSettings';
import {fiveSettings} from '../data/adminSettings/fiveSettings';
import {tiktokSettings} from '../data/adminSettings/tiktokSettings';
import {tiktokSettingsLarge} from '../data/adminSettings/tiktokSettingsLarge';
import {fiftySettings} from '../data/adminSettings/fiftySettings';
import {gridSettings} from '../data/adminSettings/gridSettings';
import {scoreSettings2} from '../data/adminSettings/scoreSetting2';
import {scoreSettings} from '../data/adminSettings/scoreSettings';
import {defaultSettings} from '../data/adminSettings/defaultSettings';
import {otherSettings} from '../data/adminSettings/otherSettings';
import axios from 'axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import {goalieStats, skaterStats} from '../data/autoPosts/nhl';
import {battingStats, fieldingStats, pitchingStats} from '../data/autoPosts/mlb';
import {defensiveStatsNCAA, passingStatsNCAA, receivingStatsNCAA, rushingStatsNCAA} from '../data/autoPosts/ncaa';
import {byeWeeks, defensiveStats, kickingStats, passingStats, puntingStats, receivingStats, rushingStats, specialStats} from '../data/autoPosts/nfl';
import {perGameStats, totalStats} from '../data/autoPosts/nba';
import {autoPosts} from '../data/autoPosts/autoPosts';
import {combinedSettings} from '../data/adminSettings/combinedSettings';
import {settingsStylesDark} from '../data/styles/settingsStylesDark';
import useSettingsStore from '../stores/settings.store';
import usePreferencesStore from '../stores/preferences.store';
import usePostStore from '../stores/posts/base-post.store';
import useUserStore from '../stores/user.store';
import useGlobalStore from '../stores/global.store';
import useSidebarStore from '../stores/sidebar.store';

export default function AutoPosts(props) {
	const {
		response,
		setResponse,
		responseSize,
		setResponseSize,
		selectedPosition,
		setSelectedPosition,
		extraScore,
		setExtraScore,
		tiktok,
		setTiktok,
		rookie,
		setRookie,
		selectedAutoStat,
		setSelectedAutoStat,
		articleID,
		setArticleID,
		selectedCBBConference,
		setSelectedCBBConference,
		selectedCFBConference,
		setSelectedCFBConference
	} = useSidebarStore();
	const { sport } = useUserStore();
	const { settings, updateSetting, replaceSettings } = useSettingsStore();
	const { darkMode } = usePreferencesStore();
	const { 
		team, name, stat, points, image, nflRanks, espnRanks, proRanks, opponents, oppRanks, combined, 
		setTitle, setSubtitle, setTeam, setName, setStat, setPoints, setRanks, setImage, setNflRanks, setEspnRanks, setProRanks, setOpponents, setOppRanks, setCombined 
	} = usePostStore();
	const { setLoading, statFilter, setStatFilter, week, setWeek, setForceGraphicUpdate, date, setDate, setExpanded, debug } = useGlobalStore();
	const [key, setKey] = useState(0);

	const subtractDay = () => {
		setDate(date.subtract(1, 'day'));
		setKey((prevKey) => prevKey + 1);
	};

	const addDay = () => {
		setDate(date.add(1, 'day'));
		setKey((prevKey) => prevKey + 1);
	};

	const countDecimals = (num) => {
		if (isNaN(num)) return 0;
		num = Number(num);
		if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
		if (!num.toString().includes('.')) return 0;
		return num.toString().split('.')[1].length || 0;
	};

	const handleCBBConferenceChange = (e) => {
		setSelectedCBBConference(e.target.value);
	}

	const handleCFBConferenceChange = (e) => {
		setSelectedCFBConference(e.target.value);
	}

	const selectPage = (e) => {
		const page = e.target.value;
		const start = (Number(page) - 1) * responseSize;
		const end = Number(page) * responseSize;
		if (settings.type === 'scores') {
			let slice = response.slice(start, end);
			let newName = [],
				newTeam = [],
				newStat = [],
				newTeams = [],
				newRanks = [];
			slice.map((item, i) => {
				newName.push(item.awayscore, item.homescore);
				newTeam.push(item.awayteam, item.hometeam);
				newStat.push(item.stat ? item.stat : '');
				newTeams.push({abbr: item.awayteam, id: item.awayid}, {abbr: item.hometeam, id: item.homeid});
				newRanks.push(item.awayrank?.toString().replace('99', ' '), item.homerank?.toString().replace('99', ' '));
				return item;
			});
			setName(newName);
			setTeam(newTeam);
			setStat(newStat);
			setTeams(newTeams);
			setRanks(newRanks);
		} else {
			let newName = [],
				newTeam = [],
				newStat = [],
				newImage = [],
				newPoints = [];
			let newProRanks = [],
				newEspnRanks = [],
				newNflRanks = [],
				newOpponents = [],
				newOppRanks = [];
			for (var i = start; i < end; i++) {
				if (response[i]) {
					let s = response[i].stat;
					let decimals = 0;
					const stats = response.flatMap((x) => x.stat);
					stats.forEach((stat) => (decimals = Math.max(countDecimals(stat), decimals)));
					if (s && decimals > 0) {
						s = Number(s).toFixed(decimals);
					}
					newName.push(response[i].player);
					newTeam.push(response[i].team);
					newStat.push(s);
					newImage.push(response[i].player_id);
					if (response[i].hasOwnProperty('points')) {
						newPoints.push(`${Number(response[i].points).toFixed(1)} pts`);
					}
					if (combined) {
						newProRanks.push(response[i].proRank ? response[i].proRank : 'N/A');
						newEspnRanks.push(response[i].espnRank ? response[i].espnRank : 'N/A');
						newNflRanks.push(response[i].nflRank ? response[i].nflRank : 'N/A');
						newOpponents.push(response[i].opponent ? response[i].opponent : '');
						newOppRanks.push(response[i].oppRank ? response[i].oppRank : '');
					}
				}
			}
			setName(newName);
			setTeam(newTeam);
			setStat(newStat);
			setImage(newImage);
			setPoints(newPoints);
			setProRanks(newProRanks);
			setEspnRanks(newEspnRanks);
			setNflRanks(newNflRanks);
			setOpponents(newOpponents);
			setOppRanks(newOppRanks);
		}
	};

	const filterStats = async (e) => {
		try {
			setStatFilter(e.target?.value)
			const positions = ['QB', 'RB', 'WR', 'TE'];
			const position = e.target?.value.replace('stats', '').toUpperCase();
			if (positions.includes(position)) {
				await setSelectedPosition([{value: position, label: position}]);
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const changeResponseSize = async (value) => {
		setResponseSize(value);
		const lastIndex = name.length - 1;
		const lastName = name[lastIndex];
		if (lastName && lastName.includes(',')) {
			let nameCopy = [...name];
			nameCopy.splice(lastIndex, 1, response[lastIndex].player);
			let teamCopy = [...team];
			teamCopy.splice(lastIndex, 1, response[lastIndex].team);
			setName(nameCopy);
			setTeam(teamCopy);
		}
		const stats = response.flatMap((x) => x.stat);
		let decimals = 0;
		stats.forEach((stat) => (decimals = Math.max(countDecimals(stat), decimals)));
		let nameArr = [...name],
			teamArr = [...team],
			statArr = [...stat],
			imageArr = [...image],
			pointsArr = [...points];
		let proRanksArr = [...proRanks],
			espnRanksArr = [...espnRanks],
			nflRanksArr = [...nflRanks],
			opponentsArr = [...opponents],
			oppRanksArr = [...oppRanks];
		for (var i = team.length; i < value; i++) {
			if (response[i]) {
				let currentName = response[i].player;
				if (value <= 8) {
					currentName = currentName.toUpperCase();
				}
				let currentStat = response[i].stat;
				if (currentStat && decimals > 0) {
					currentStat = Number(currentStat).toFixed(decimals);
				}
				nameArr.push(currentName);
				teamArr.push(response[i].team);
				statArr.push(currentStat);
				if (response[i].hasOwnProperty('points')) {
					pointsArr.push(`${Number(response[i].points).toFixed(1)} pts`);
				}
				imageArr.push(response[i].player_id);
				if (combined) {
					proRanksArr.push(response[i].proRank ? response[i].proRank : 'N/A');
					espnRanksArr.push(response[i].espnRank ? response[i].espnRank : 'N/A');
					nflRanksArr.push(response[i].nflRank ? response[i].nflRank : 'N/A');
					opponentsArr.push(response[i].opponent ? response[i].opponent : '');
					oppRanksArr.push(response[i].oppRank ? response[i].oppRank : '');
				}
			}
		}
		if (team.length > value) {
			setName(name.slice(0, value));
			setTeam(team.slice(0, value));
			setStat(stat.slice(0, value));
			setImage(image.slice(0, value));
			setPoints(points.slice(0, value));
			setProRanks(proRanks.slice(0, value));
			setEspnRanks(espnRanks.slice(0, value));
			setNflRanks(nflRanks.slice(0, value));
			setOpponents(opponents.slice(0, value));
			setOppRanks(oppRanks.slice(0, value));
		} else {
			setName(nameArr);
			setTeam(teamArr);
			setStat(statArr);
			setImage(imageArr);
			setPoints(pointsArr);
			setProRanks(proRanksArr);
			setEspnRanks(espnRanksArr);
			setNflRanks(nflRanksArr);
			setOpponents(opponentsArr);
			setOppRanks(oppRanksArr);
		}
	};

	const parseDateString = (inputString) => {
		const formats = ['MMM DD, YYYY, hh:mm A Z', 'YYYY-MM-DD HH:mm:ss Z'];
		let parsedDate;
		for (const format of formats) {
			parsedDate = moment(inputString, format, false);
			if (parsedDate.isValid()) {
				break;
			}
		}

		return parsedDate.isValid() ? parsedDate : null;
	};

	const renderNew = () => {
		const yesterday = moment().subtract(1, 'day');
		let subtitles = {
			subtitle0: `Through ${date.format('M/D')}`,
			subtitle1: 'According to ESPN',
			subtitle2: 'According to NBA.com',
			subtitle3: date.year(),
			subtitle4: `Per Game through Week ${week}`,
			subtitle5: `Through Week ${week}`,
			subtitle6: 'According to FantasyPros (PPR)',
			subtitle7: `Week ${week}`,
			subtitle8: 'According to NFL.com',
			subtitle9: 'According to NFL.com (PPR)',
			subtitle10: 'According to FantasyPros',
			subtitle11: 'According to ESPN (PPR)',
			subtitle12: 'According to FantasyPros (Standard)',
			subtitle13: 'According to ESPN (Standard)',
			subtitle14: date.format('L'),
			subtitle15: `ROUND ${articleID}`,
			subtitle16: `According to Bleacher Report`,
			subtitle17: `According to CBS`,
			subtitle18: yesterday.format('L'),
			subtitle19: `According to MLB.com`,
			subtitle20: `According to PFF`,
			subtitle21: `Last 3 Weeks`,
			subtitle22: `According to Sports Illustrated`,
			subtitle23: `According to NHL.com`,
			subtitle24: 'According to DraftKings',
			subtitle25: 'Owned in <50% of Leagues',
			subtitle26: `Week ${week + 1}`,
			subtitle27: 'Fantasy Leaders (PPR)',
			subtitle28: 'According to ESPN BET',
		};
		let end = date.diff(moment('2024-10-03'), 'days');
		let end2 = date.diff(moment('2024-10-21'), 'days');
		let end3 = date.diff(moment('2024-03-19'), 'days');
		let positions = selectedPosition?.length
			? selectedPosition
					.map((opt) => {
						return opt['value'];
					})
					.join()
			: 'ALL';
		if (positions.includes('ALL')) positions = 'ALL';
		if (positions.includes('RB')) positions += ',FB';
		if (settings.type === 'scores') {
			if (sport === 'nhl') {
				return (
					<div className='rendContainer'>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`${sport}/scores/${date.format('YYYYMMDD')}?notes=false`, 'SCOREBOARD', subtitles.subtitle14, 100);
							}}>
							Scoreboard
						</button>
					</div>
				);
			} else if (sport === 'nfl') {
				return (
					<div className='rendContainer'>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`${sport}/scores/${week}`, 'SCOREBOARD', subtitles.subtitle7, 100, 'week');
							}}>
							Scoreboard
						</button>
					</div>
				);
			} else if (sport === 'ncaa') {
				return (
					<div className='rendContainer'>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`${sport}/scores/${week}`, 'TOP 25 SCOREBOARD', subtitles.subtitle7, 100, 'week');
							}}>
							Top 25 CFB Scoreboard
						</button>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`cbb/scores/${date.format('YYYYMMDD')}`, 'TOP 25 SCOREBOARD', subtitles.subtitle14, 100);
							}}>
							Top 25 CBB Scoreboard
						</button>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`ncaabydiv/scores/${selectedCFBConference}/${week}`, 'SCOREBOARD', subtitles.subtitle7, 20, 'week');
							}}>
							CFB Scoreboard By Div.
						</button>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`cbbbydiv/scores/${selectedCBBConference}/${date.format('YYYYMMDD')}`, 'SCOREBOARD', subtitles.subtitle14, 24);
							}}>
							CBB Scoreboard By Div.
						</button>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`${sport}/schedule/${week + 1}`, 'TOP 25 SCHEDULE', subtitles.subtitle26, 100, 'week');
							}}>
							Top 25 CFB Schedule
						</button>
					</div>
				);
			} else {
				return (
					<div className='rendContainer'>
						<button
							className='newButton'
							onClick={() => {
								getfromdb(`${sport}/scores/${date.format('YYYYMMDD')}?notes=${sport === 'mlb' ? true : extraScore}`, 'SCOREBOARD', subtitles.subtitle14, 100);
							}}>
							Scoreboard
						</button>
					</div>
				);
			}
		}
		let buttons = autoPosts.filter((post) => post.type === statFilter && post.sport === sport);
		return (
			<div className='rendContainer'>
				<div id='auto1'>
					{sport === 'mlb' && (
						<select
							name='auto1'
							id='auto1select'
							value={statFilter}
							onChange={filterStats}>
							<option value='daily'>Daily Leaders</option>
							<option value='batting'>Batting Stats</option>
							<option value='pitching'>Pitching Stats</option>
							<option value='reliever'>Reliever Stats</option>
							<option value='team'>Team Stats</option>
							<option value='power'>Power Rankings</option>
							<option value='other'>Other</option>
						</select>
					)}
					{sport === 'nba' && (
						<select
							name='auto1'
							id='auto1select'
							value={statFilter}
							onChange={filterStats}>
							<option value='daily'>Daily Stats</option>
							<option value='traditional'>Weekly Stats</option>
							<option value='additional'>Other Stats</option>
							<option value='team'>Team Stats</option>
							<option value='opponent'>Opp. Stats</option>
							<option value='power'>Power Rankings</option>
							<option value='other'>Other</option>
						</select>
					)}
					{sport === 'ncaa' && (
						<select
							name='auto1'
							id='auto1select'
							value={statFilter}
							onChange={filterStats}>
							<option value='daily'>Daily Stats</option>
							<option value='qbstats'>QB Stats</option>
							<option value='rbstats'>RB Stats</option>
							<option value='wrstats'>WR Stats</option>
							<option value='testats'>TE Stats</option>
							<option value='idpstats'>IDP Stats</option>
							<option value='otherstats'>Other Stats</option>
							<option value='teamstats'>Team Stats</option>
							<option value='ballstats'>Basketball Stats</option>
							<option value='teamballstats'>Team BBall Stats</option>
							<option value='appoll'>AP Poll</option>
							<option value='other'>Other</option>
						</select>
					)}
					{sport === 'nhl' && (
						<select
							name='auto1'
							id='auto1select'
							value={statFilter}
							onChange={filterStats}>
							<option value='daily'>Daily Stats</option>
							<option value='offensive'>Offensive Stats</option>
							<option value='goalie'>Goalie Stats</option>
							<option value='team'>Team Stats</option>
							<option value='power'>Power Rankings</option>
						</select>
					)}
					{sport === 'nfl' && (
						<select
							name='auto1'
							id='auto1select'
							value={statFilter}
							onChange={filterStats}>
							<option value='fantasyleaders'>Weekly Leaders</option>
							<option value='slate'>Slate Leaders</option>
							<option value='qbstats'>QB Stats</option>
							<option value='rbstats'>RB Stats</option>
							<option value='recstats'>REC Stats</option>
							<option value='idpstats'>IDP Stats</option>
							<option value='otherstats'>Other Stats</option>
							<option value='teamstats'>Team Stats</option>
							<option value='fantasyproj'>Fantasy Projections</option>
							<option value='pprranks'>PPR Ranks</option>
							<option value='combined'>Combined Ranks</option>
							<option value='stdranks'>Standard Ranks</option>
							<option value='fantasyseason'>Season Leaders</option>
							<option value='powerranks'>Power Rankings</option>
							<option value='gridstats'>Grid Stats</option>
							<option value='odds'>Odds</option>
							<option value='other'>Other</option>
						</select>
					)}
				</div>
				{buttons.map((butt, i) => {
					let id = butt.id;
					let requiresId = '';
					if (butt.specialEnd) {
						switch (butt.specialEnd) {
							case 'date':
								id = id + `/${date.format('YYYYMMDD')}`;
								break;
							case 'date2':
								id = id + `/${date.format('YYMMDD')}`;
								break;
							case 'date3':
								id = id + `/${date.format('YYYY-MM-DD')}`;
								break;
							case 'article':
								if (!butt.endOptional) {
									id = id + `/${articleID}`;
									requiresId = 'article';
								} else if (articleID) {
									id = id + `/${articleID}`;
								}
								break;
							case 'week':
								id = id + `/${week}`;
								requiresId = 'week';
								break;
							case 'year/article':
								id = id + `/${date.year()}/${articleID}`;
								requiresId = 'article';
								break;
							case 'year/week':
								id = id + `/${date.year()}/${week}`;
								requiresId = 'week';
								break;
							case 'positions':
								id = id + `/${positions}`;
								break;
							case 'cfb':
								id = id + `/${selectedCFBConference}`;
								break;
							case 'cfbWeek':
								id = id + `/${selectedCFBConference}?week=${week}`;
								requiresId = 'week';
								break;
							case 'cbb':
								id = id + `/${selectedCBBConference}`;
								break;
							case 'end':
								id = id + `/${end}`;
								break;
							case 'end2':
								id = id + `/${end2}`;
								break;
							case 'end3':
								id = id + `/${end3}`;
								break;
							default:
							// do nothing
						}
					}
					return (
						<button
							key={i}
							id={butt.id}
							className='newButton'
							onClick={() => {
								getfromdb(id, butt.title, butt.textsubtitle ? butt.textsubtitle : subtitles[butt.subtitle], butt.tikTokExpand && tiktok ? butt.size + 5 : butt.size, requiresId);
							}}>
							{butt.button ? butt.button : butt.title}
						</button>
					);
				})}
			</div>
		);
	};

	const getfromdb = async (id, title, subtitle, slicenum, requiresId) => {
		if (document.documentElement.clientWidth <= 950) {
			setExpanded('');
		}
		if (document.getElementById('paginateselect')?.value > 1) {
			document.getElementById('paginateselect').value = 1;
		}
		if (requiresId && requiresId.includes('article') && !articleID) {
			toast.error('Please provide an article ID');
			return;
		}
		if (requiresId && requiresId.includes('week') && !week) {
			toast.error('Please provide a week');
			return;
		}
		props.reset();
		setLoading(true);
		const filter = statFilter;
		if (settings.type !== 'grid' && filter === 'gridstats') {
			await props.changeType2('grid');
		}
		setResponseSize(slicenum);
		try {
			let res = [];
			if (rookie) {
				const idPost = autoPosts.find((post) => post.id === id);
				const rookieUrl = idPost.rookie ? '/stats/' + idPost.rookie : `/stats/${id}/rookie`;
				res = await axios.get(rookieUrl);
			} else if (id.includes('random')) {
				res = await axios.post(`/stats/articles/random`, {
					url: articleID
				});
			} else {
				res = await axios.get(`/stats/${id}`);
			}
			setResponse(res.data);
			if (id.includes('power') && res.data[0]?.date) {
				const today = moment();
				const articleDate = parseDateString(res.data[0].date);
				if (today.diff(articleDate, 'days') > 5) {
					toast.error(`Power rankings older than 5 days - ${articleDate.format('MMM Do YY')}`);
					await setLoading(false);
					return;
				}
			}
			let number = slicenum;
			if (!res.data.length) {
				toast.error('Something went wrong.');
				setLoading(false);
				return
			}
			let slice = res.data.slice(0, number);
			if (id.includes('scores') || id.includes('schedule')) {
				let nameArr = [],
					teamArr = [],
					statArr = [],
					teamsArr = [],
					rankArr = [];
				slice.map((item, i) => {
					nameArr.push(item.awayscore, item.homescore);
					teamArr.push(item.awayteam, item.hometeam);
					statArr.push(item.stat ? String(item.stat) : '');
					teamsArr.push({abbr: item.awayteam, id: item.awayid}, {abbr: item.hometeam, id: item.homeid});
					rankArr.push(item.awayrank?.toString().replace('99', ' '), item.homerank?.toString().replace('99', ' '));
					return item;
				});
				setName(nameArr);
				setTeam(teamArr);
				setStat(statArr);
				setTeam(teamArr);
				setRanks(rankArr);
			} else if ((id.includes('perfect') || id.includes('worst')) && filter === 'gridstats') {
				const slots = [[0], [2], [2], [4], [4], [6], [2, 4, 6], [16], [17]];
				let usedPlayers = [];
				let nameArr = [],
					teamArr = [],
					statArr = [],
					sumArr = [];
				slots.forEach((slot, i) => {
					let item = res.data.find((x) => {
						return x.slots.some((y) => slot.indexOf(y) >= 0) && !usedPlayers.includes(x.player);
					});
					if (item) {
						usedPlayers.push(item.player);
					} else {
						item = {
							player: '',
							team: '',
							stat: '',
							player_id: '',
							points: 0,
							startperc: 0,
							slots: [],
							jersey: '0',
							team_id: 0,
							opponent: '',
						};
					}
					let newName = item.player.replace('##', ' ');
					let newPoints = Number(item.points).toFixed(1);
					let newTeam = item.team;
					if (item.opponent.includes('@')) {
						newTeam = item.team + 2;
					}
					if (newName.length > 12 && !newName.includes('D/ST')) {
						let lastname = newName.substr(newName.indexOf(' ') + 1);
						newName = `${newName.charAt(0)}. ${lastname}`;
					}
					nameArr.push(`${newName} ${newPoints} pts`);
					teamArr.push(newTeam);
					statArr.push(Number(item.jersey));
					sumArr.push(Number(newPoints));
				});
				let settingsToSet = {...gridSettings};
				settingsToSet['watermark'] = '@FAN\nTASY\nFTBL';
				settingsToSet['jerseyBox'] = true;
				settingsToSet['borderColor'] = '#000000';
				settingsToSet['borderRadius'] = '0';
				if (id.includes('perfect') || id.includes('worst')) {
					settingsToSet.gridMargin = 0;
				}
				if (id.includes('perfectone') || id.includes('worstone')) {
					settingsToSet.hasFooter = true;
					const footerPlayers = res.data.filter((x) => {
						return !usedPlayers.includes(x.player) && x.points !== 0;
					});
					footerPlayers.forEach((f) => sumArr.push(f.points));
					settingsToSet.footerText = footerPlayers.map((f) => f.player.replace('##', ' ') + ' ' + f.points + ' pts').join(', ');
				}
				let sum = sumArr.reduce((a, b) => a + b);
				replaceSettings(settingsToSet);
				setTitle(title.toUpperCase());
				setName(nameArr);
				setTeam(teamArr);
				setStat(statArr);
				setSubtitle(`Week ${week} - ${sum.toFixed(1)} pts`.toUpperCase());
				setLoading(false);
				window.dispatchEvent(new Event('resize'));
				return;
			} else {
				let remainder = res.data.slice(number);
				let ultimate = number - 1;
				let filtered = remainder.filter((stat) => stat.stat === slice[ultimate].stat);
				const stats = slice.flatMap((x) => x.stat);
				let decimals = 0;
				stats.forEach((stat) => (decimals = Math.max(countDecimals(stat), decimals)));
				let nameArr = [],
					teamArr = [],
					statArr = [],
					imageArr = [],
					pointsArr = [];
				let proRanksArr = [],
					espnRanksArr = [],
					nflRanksArr = [],
					opponentsArr = [],
					oppRanksArr = [];
				slice.map((item, i) => {
					let newName = item.player;
					let newTeam = item.team;
					let newStat = item.stat;
					if (item.stat && decimals > 0) {
						newStat = Number(item.stat).toFixed(decimals);
					}
					if (i === ultimate && filtered.length > 0 && number !== 5 && !isNaN(newStat)) {
						filtered.map((fil) => {
							newName = newName.concat(', ' + fil.player.trim());
							return fil;
						});
						newTeam = 'blank';
					}
					if (number === 5) {
						newName = newName.toUpperCase();
						newStat = newStat.toUpperCase();
					}
					nameArr.push(newName);
					teamArr.push(newTeam);
					if (newStat !== undefined) {
						statArr.push(String(newStat));
					} else {
						statArr.push('');
					}
					if (item.hasOwnProperty('player_id')) {
						let playerId = item.player_id;
						if (team === 'blank') {
							playerId = '';
						}
						imageArr.push(playerId);
					}
					if (item.hasOwnProperty('points')) {
						pointsArr.push(`${Number(item.points).toFixed(1)} pts`);
					} else if (item.hasOwnProperty('odds')) {
						pointsArr.push(item.odds);
					}
					if (id.includes('combined')) {
						setCombined(true);
						proRanksArr.push(item.proRank ? item.proRank : 'N/A');
						espnRanksArr.push(item.espnRank ? item.espnRank : 'N/A');
						nflRanksArr.push(item.nflRank ? item.nflRank : 'N/A');
						opponentsArr.push(item.opponent ? item.opponent : '');
						oppRanksArr.push(item.oppRank ? item.oppRank : '');
					}
					return item;
				});
				if (id.includes('nba/daily') || id.includes('nba/playoffs')) {
					if (slice.length < number) {
						for (let p = slice.length; p < number; p++) {
							teamArr.push('');
						}
					}
				}
				if (id.includes('injuries')) {
					if (slice.length < 30) {
						for (let p = slice.length; p < 30; p++) {
							teamArr.push('');
						}
					}
				}
				if ((filter === 'pprranks' || filter === 'stdranks' || filter === 'fantasyproj') && [5, 6, 7, 9, 10, 11, 13, 14].includes(Number(week))) {
					if (slice.length <= 25) {
						teamArr.push('');
						nameArr.push(`Bye Week: ${byeWeeks[week]}`);
					} else {
						teamArr = teamArr.concat(['', '']);
						nameArr = nameArr.concat([`Bye Week:`, `${byeWeeks[week]}`]);
					}
				}
				setName(nameArr);
				setTeam(teamArr);
				setStat(statArr);
				setImage(imageArr);
				setPoints(pointsArr);
				setProRanks(proRanksArr);
				setEspnRanks(espnRanksArr);
				setNflRanks(nflRanksArr);
				setOpponents(opponentsArr);
				setOppRanks(oppRanksArr);
				if ((!imageArr.length && number === 5) || id.includes('salaries')) {
					props.getImagebyID(nameArr, teamArr);
				}
				teamArr.forEach((teamArrLine) => {
					if (teamArrLine === 'subtitle') return;
					if (teamArrLine === 'blank') return;
					if (teamArrLine === '') return;
					if (teamArrLine.includes('/')) return;
					const foundTeam = props.getTeamObj(teamArrLine.toUpperCase(), sport);
					if (!foundTeam.logo) {
						toast.error(`no matching team found for ${teamArrLine}`);
						props.reset();
					}
				})
			}
			if ([3, 4, 5].includes(date.isoWeekday()) && filter && (filter.includes('stats') || filter === 'fantasyseason') && subtitle.includes(week) && sport === 'nfl') {
				subtitle = subtitle.replace(week, week - 1);
			}
			if (id.includes('appoll') && subtitle.includes('Week')) {
				subtitle = subtitle.replace(week, week + 1);
			}
			setTitle(title.toUpperCase());
			setSubtitle(subtitle);
			setLoading(false);
			if (rookie) {
				setTitle(`ROOKIE ${title.toUpperCase()}`);
			}
			if (sport === 'ncaa') {
				let el, conference;
				if ((filter === 'ballstats' || id.includes('mens-college-basketball')) && Number(selectedCBBConference) !== 50 && Number(selectedCBBConference) !== 0) {
					el = document.getElementById('cbbconference');
					conference = el.options[el.selectedIndex].text.toUpperCase();
					setTitle(`${conference} ${title.toUpperCase()}`);
				} else if (Number(selectedCFBConference) > 0) {
					el = document.getElementById('cfbconference');
					conference = el.options[el.selectedIndex].text.toUpperCase();
					setTitle(`${conference} ${title.toUpperCase()}`);
				}
			}
			if (tiktok && team.length >= 20) {
				replaceSettings(tiktokSettingsLarge);
			} else if (tiktok) {
				replaceSettings(tiktokSettings);
			} else if (id.includes('combined')) {
				replaceSettings(combinedSettings);
			} else if (number === 5) {
				replaceSettings(fiveSettings);
			} else if (number === 10) {
				let settingsToSet = {...tenSettings};
				replaceSettings(settingsToSet);
			} else if (number === 25 || number === 26 || id.includes('playoffs')) {
				let settingsToSet = {...twentyFiveSettings};
				if (id.includes('playoffs')) {
					settingsToSet.nameSize = 16;
				}
				replaceSettings(settingsToSet);
			} else if (number === 50) {
				replaceSettings(fiftySettings);
			} else if (id.includes('draftbydiv')) {
				let draftSettings = {...fiftySettings};
				draftSettings.nameSize = 20;
				draftSettings.statSize = 20;
				draftSettings.logoSize = 50;
				replaceSettings(draftSettings);
			} else if (filter === 'gridstats') {
				let settingsToSet = {...gridSettings};
				if (id.includes('eachteam')) {
					settingsToSet.watermark = '';
					settingsToSet.hasFooter = true;
					settingsToSet.footerText = '@FANTASYFTBL';
				}
				replaceSettings(settingsToSet);
			} else if (sport === 'ncaa' && settings.type === 'scores') {
				let settingsToSet = {...scoreSettings2};
				if (id.includes('schedule')) {
					settingsToSet.statMargin = 2;
					settingsToSet.statFont = 'roboto condensed';
					settingsToSet.rankMargin = 9;
					settingsToSet.rankSize = 19;
					settingsToSet.nameSize = 10;
				}
				replaceSettings(settingsToSet);
			} else if (settings.type === 'scores') {
				let settingsToSet = {...scoreSettings};
				if (sport === 'nba') {
					settingsToSet.nameSize = 27;
					settingsToSet.nameSpacing = -2;
				}
				replaceSettings(settingsToSet);
			} else if (sport === 'ncaa' && number > 50) {
				let settingsToSet = {...defaultSettings};
				settingsToSet.nameSize = 20;
				settingsToSet.statSpacing = -1;
				settingsToSet.statFont = 'roboto bold';
				replaceSettings(settingsToSet);
			} else if (id.includes('injuries')) {
				let injurySettings = {...defaultSettings};
				injurySettings.nameSize = 22;
				injurySettings.nameSpacing = -1;
				injurySettings.nameMargin2 = 6;
				injurySettings.statSize = 25;
				injurySettings.statFont = 'roboto bold';
				replaceSettings(injurySettings);
			} else if (id.includes('standings/nfl') || id.includes('standings/mlb')) {
				replaceSettings(otherSettings);
			} else {
				replaceSettings(defaultSettings);
			}
			if (tiktok || (sport === 'ncaa' && Number(selectedCBBConference) !== 50 && Number(selectedCBBConference) !== 0)) {
				updateSetting('watermark', '');
			} else if (sport === 'mlb') {
				updateSetting('watermark', 'BSBL\nSTAT\nPAGE');
			} else if (sport === 'nfl') {
				if (
					(filter === 'fantasyleaders' && !title.includes('Yards')) ||
					filter === 'fantasyproj' ||
					filter === 'slate' ||
					filter === 'pprranks' ||
					filter === 'stdranks' ||
					filter === 'combined' ||
					filter === 'fantasyseason' ||
					(filter === 'gridstats' && !id.includes('eachteam')) ||
					id.includes('salaries') ||
					id.includes('injuries') ||
					id.includes('waivers') ||
					id.includes('snapcount') ||
					id.includes('targetshare')
				) {
					updateSetting('watermark', '@FAN\nTASY\nFTBL');
				} else if (!id.includes('eachteam')) {
					updateSetting('watermark', 'FTBL\nSTAT\nPAGE');
				}
			} else if (sport === 'nba') {
				updateSetting('watermark', 'BALL\nSTAT\nPAGE');
			} else if (sport === 'ncaa') {
				if ((filter && filter.includes('ballstats')) || id.includes('cbb') || id.includes('ncaa/daily')) {
					updateSetting('watermark', '@CBB\nSTAT\nPAGE');
				} else {
					updateSetting('watermark', '@CFB\nSTAT\nPAGE');
				}
			} else if (sport === 'nhl') {
				updateSetting('watermark', '@ICE\nSTAT\nPAGE');
			}
			// force update to get text fit to work properly
			setForceGraphicUpdate();
			window.dispatchEvent(new Event('resize'));
		} catch (e) {
			if (debug) {
				console.log(e);
			}
			toast.error('Something went wrong.');
			setLoading(false);
		}
	};

	return (
		<span className='adminsettings'>
			<span className='autosettings'>
				<span className='autosettingsspan'>
					<label className='settings-label'>Week</label>
					<textarea
						rows='1'
						value={week}
						onChange={(event) => {
							setWeek(event.target.value);
						}}
					/>
				</span>
				<span className='autosettingsspan'>
					<label className='settings-label'>Article ID</label>
					<textarea
						rows='1'
						value={articleID}
						onChange={(event) => {
							setArticleID(event.target.value);
						}}
					/>
				</span>
				<div
					className='autoflex'
					id='conference2'>
					<label className='settings-label'>CFB Conference</label>
					<select
						name='conference2'
						value={selectedCFBConference}
						onChange={handleCFBConferenceChange}
						id='cfbconference'>
						<option value='0'>--</option>
						<option value='1'>ACC</option>
						<option value='151'>American</option>
						<option value='4'>Big 12</option>
						<option value='5'>Big Ten</option>
						<option value='12'>C-USA</option>
						<option value='18'>Independent</option>
						<option value='15'>MAC</option>
						<option value='17'>Mtn. West</option>
						<option value='9'>Pac-12</option>
						<option value='8'>SEC</option>
						<option value='37'>Sun Belt</option>
					</select>
				</div>
				<div
					className='autoflex'
					id='conference'>
					<label className='settings-label'>CBB Conference</label>
					<select
						name='conference'
						value={selectedCBBConference}
						onChange={handleCBBConferenceChange}
						id='cbbconference'>
						<option value='50'>--</option>
						<option value='1'>Am. East</option>
						<option value='2'>ACC</option>
						<option value='3'>A 10</option>
						<option value='4'>Big East</option>
						<option value='5'>Big Sky</option>
						<option value='6'>Big South</option>
						<option value='7'>Big Ten</option>
						<option value='8'>Big 12</option>
						<option value='9'>Big West</option>
						<option value='10'>CAA</option>
						<option value='11'>C-USA</option>
						<option value='12'>Ivy</option>
						<option value='13'>MAAC</option>
						<option value='14'>MAC</option>
						<option value='16'>MEAC</option>
						<option value='18'>MVC</option>
						<option value='19'>NEC</option>
						<option value='20'>OVC</option>
						<option value='21'>Pac 12</option>
						<option value='22'>Patriot</option>
						<option value='23'>SEC</option>
						<option value='24'>Southern</option>
						<option value='25'>Southland</option>
						<option value='26'>SWAC</option>
						<option value='27'>Sun Belt</option>
						<option value='29'>WCC</option>
						<option value='30'>WAC</option>
						<option value='44'>Mtn West</option>
						<option value='45'>Horizon</option>
						<option value='46'>ASUN</option>
						<option value='49'>Summit</option>
						<option value='62'>American</option>
					</select>
				</div>
				<span className='autosettingsspan'>
					<button
						id='previous-day'
						className='calendarButton'
						onClick={subtractDay}>
						-
					</button>
					<DatePicker
						key={key}
						selected={date.toDate()}
						onChange={(d) => setDate(moment(d))}
					/>
					<button
						className='calendarButton'
						onClick={addDay}>
						+
					</button>
				</span>
				<span className='autosettingsspan'>
					<input
						type='checkbox'
						id='rookie'
						checked={rookie}
						onChange={() => setRookie(!rookie)}
					/>
					<label
						className='settings-label'
						htmlFor='rookie'>
						Rookie
					</label>
				</span>
				<span className='autosettingsspan'>
					<input
						type='checkbox'
						id='tiktok'
						checked={tiktok}
						onChange={() => setTiktok(!tiktok)}
					/>
					<label
						className='settings-label'
						htmlFor='tiktok'>
						Tik Tok
					</label>
				</span>
				{settings.type === 'scores' && (
					<span className='autosettingsspan'>
						<input
							type='checkbox'
							id='extrascore'
							checked={extraScore}
							onChange={() => setExtraScore(!extraScore)}
						/>
						<label
							className='settings-label'
							htmlFor='extrascore'>
							Score Info
						</label>
					</span>
				)}
				{(sport === 'nfl' || sport === 'ncaa') && (
					<Select
						id='positionDropdown'
						instanceId='position'
						value={selectedPosition}
						onChange={(selected) => setSelectedPosition(selected)}
						options={[
							{value: 'ALL', label: 'ALL'},
							{value: 'QB', label: 'QB'},
							{value: 'RB', label: 'RB'},
							{value: 'WR', label: 'WR'},
							{value: 'TE', label: 'TE'},
						]}
						styles={darkMode ? settingsStylesDark : settingsStyles}
						isMulti
					/>
				)}
			</span>
			{(settings.type.includes('post') || settings.type.includes('grid')) && response.length !== responseSize && (
				<span className='autosettingsspan responsesizespan'>
					<button
						className='calendarButton'
						onClick={() => changeResponseSize(responseSize - 1)}>
						-
					</button>
					<Slider
						railStyle={{backgroundColor: 'lightgray'}}
						trackStyle={{backgroundColor: '#00a199'}}
						handleStyle={{borderColor: '#00a199'}}
						value={responseSize}
						onChange={changeResponseSize}
						min={5}
						max={Math.max(Math.min(response.length, 50), 5)}
					/>
					<button
						className='calendarButton'
						onClick={() => changeResponseSize(responseSize + 1)}>
						+
					</button>
				</span>
			)}
			{response.length > responseSize && (
				<div id='paginate'>
					<label className='settings-label'>Page Number</label>
					<select
						name='paginate'
						id='paginateselect'
						onChange={selectPage}>
						<option value='1'>Page 1</option>
						<option value='2'>Page 2</option>
						{response.length > responseSize * 2 && <option value='3'>Page 3</option>}
						{response.length > responseSize * 3 && <option value='4'>Page 4</option>}
						{response.length > responseSize * 4 && <option value='5'>Page 5</option>}
						{response.length > responseSize * 5 && <option value='6'>Page 6</option>}
						{response.length > responseSize * 6 && <option value='7'>Page 7</option>}
						{response.length > responseSize * 7 && <option value='8'>Page 8</option>}
						{response.length > responseSize * 8 && <option value='9'>Page 9</option>}
						{response.length > responseSize * 9 && <option value='10'>Page 10</option>}
					</select>
				</div>
			)}
			{sport && (
				<div id='autostats'>
					{sport === 'nba' && (
						<Select
							value={selectedAutoStat}
							onChange={(s) => setSelectedAutoStat(s)}
							options={[
								{
									label: 'Per Game Stats',
									options: perGameStats,
								},
								{
									label: 'Total Stats',
									options: totalStats,
								},
							]}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={true}
						/>
					)}
					{sport === 'nfl' && (
						<Select
							value={selectedAutoStat}
							onChange={(s) => setSelectedAutoStat(s)}
							options={[
								{
									label: 'Passing Stats',
									options: passingStats,
								},
								{
									label: 'Rushing Stats',
									options: rushingStats,
								},
								{
									label: 'Receiving Stats',
									options: receivingStats,
								},
								{
									label: 'Defensive Stats',
									options: defensiveStats,
								},
								{
									label: 'Special Team Stats',
									options: specialStats,
								},
								{
									label: 'Kicking Stats',
									options: kickingStats,
								},
								{
									label: 'Punting Stats',
									options: puntingStats,
								},
							]}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={true}
						/>
					)}
					{sport === 'ncaa' && (
						<Select
							value={selectedAutoStat}
							onChange={(s) => setSelectedAutoStat(s)}
							options={[
								{
									label: 'Passing Stats',
									options: passingStatsNCAA,
								},
								{
									label: 'Rushing Stats',
									options: rushingStatsNCAA,
								},
								{
									label: 'Receiving Stats',
									options: receivingStatsNCAA,
								},
								{
									label: 'Defensive Stats',
									options: defensiveStatsNCAA,
								},
							]}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={true}
						/>
					)}
					{sport === 'nhl' && (
						<Select
							value={selectedAutoStat}
							onChange={(s) => setSelectedAutoStat(s)}
							options={[
								{
									label: 'Skater Stats',
									options: skaterStats,
								},
								{
									label: 'Goalie Stats',
									options: goalieStats,
								},
							]}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={true}
						/>
					)}
					{sport === 'mlb' && (
						<Select
							value={selectedAutoStat}
							onChange={(s) => setSelectedAutoStat(s)}
							options={[
								{
									label: 'Batting Stats',
									options: battingStats,
								},
								{
									label: 'Pitching Stats',
									options: pitchingStats,
								},
								{
									label: 'Fielding Stats',
									options: fieldingStats,
								},
							]}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={true}
						/>
					)}
				</div>
			)}
			{selectedAutoStat && (
				<button
					className='newButton'
					onClick={() => {
						getfromdb(
							`2021/${selectedAutoStat.value}/${selectedAutoStat.qualify === false ? false : true}/${selectedAutoStat.direction ? selectedAutoStat.direction : 'asc'}`,
							`${selectedAutoStat.label}`,
							`Through ${date.format('M/D')}`,
							responseSize
						);
					}}>{`Get ${selectedAutoStat.label}`}</button>
			)}
			{renderNew()}
		</span>
	);
}
